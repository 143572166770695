import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

const SelectSurvey = (props) => {
  const { options, handleButtons, value, onChange } = props;
  const surveyCtx = useContext(SurveyContext);

  return (
    <div className="no-print" style={{ marginBottom: '10px', width: '100%' }}>
      <div className="form-group row center m-0 p-2">
        <select
          onChange={onChange}
          className="form-control m-2"
          name="selected_survey"
          id="surveys"
          value={value}
          style={{ width: '550px' }}
        >
          <option value="" disabled>
            Escolha uma Avaliação:
          </option>
          {Object.keys(options).map((key) => (
            <option key={options[key].value} value={options[key].value}>
              {options[key].text}
            </option>
          ))}
        </select>
      </div>
      {surveyCtx.selectedSurvey && (
        <div className="form-group row center m-0 p-2">
          <button
            onClick={handleButtons}
            name="new"
            className="btn btn-success ml-2 "
          >
            Criar Nova
          </button>
          <button
            onClick={handleButtons}
            name="list"
            className="btn btn-info ml-2 "
          >
            Consultar/Alterar
          </button>
          <button
            onClick={handleButtons}
            name="stats"
            className="btn btn-secondary ml-2 "
          >
            Estatisticas
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectSurvey;
