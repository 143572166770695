import React from 'react';

const QuestionHeader = (props) => {
  return (
    <span className="d-block">
      <p>
        {props.questionNum && (
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {props.questionNum.toString().replace('_', '-')}.{' '}
          </span>
        )}
        <span style={{ fontSize: '18px' }}>{props.children}</span>
      </p>
    </span>
  );
};

export default QuestionHeader;
