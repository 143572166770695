export const gridConfig = {
  defaultColumn: {
    width: 200,
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    filter: true,
  },
  colWidth: {
    date: 125,
    service: 300,
    createdby: 200,
    formador: 300,
    entity: 300,
    name: 275,
    comment: 300,
  },
};
