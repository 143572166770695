import React, { useContext, useState, useEffect } from 'react';
import { Select } from 'antd';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const SelectFormadorNew = ({
  onChange,
  placeholder = 'Formador',
  todos = false,
  width = '250px',
}) => {
  const auth = useContext(AuthContext);

  const [loadedUsers, setLoadedUsers] = useState([]);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { Option } = Select;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users?select=name&sort=name`,
          'GET',
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setLoadedUsers(responseData.data);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth.token]);

  return (
    <div className="ml-2">
      <Select
        allowClear
        style={{ width }}
        mode="multiple"
        placeholder={placeholder}
        onChange={onChange}
      >
        {loadedUsers.map((user, key) => {
          return (
            <Option key={key} value={user._id}>
              {user.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectFormadorNew;
