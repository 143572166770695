import React, { useEffect, useState, useContext } from 'react';

import { DatePicker, Select, Checkbox } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import locale from 'antd/es/date-picker/locale/pt_PT';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { SurveyContext } from '../../../../shared/context/survey-context';
import { AuthContext } from '../../../../shared/context/auth-context';

import DataGrid from '../../../components/data/DataGrid';
import { gridConfig } from '../common/config';

const List_A2000_143 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceListToggle, setServiceListToggle] = useState(false);
  const surveyCtx = useContext(SurveyContext);

  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);
  const [filterJust, setFilterJust] = useState('');
  const [filterNegatives, setFilterNegatives] = useState(false);

  const [rowData, setRowData] = useState([]);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/${surveyCtx.selectedSurvey}/filtered`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
            justifications: filterJust,
            negatives: filterNegatives,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );

        setRowData(() => {
          return responseData.data.map((survey) => {
            return {
              survey: survey,
              date: new Date(survey.date).toLocaleDateString(),
              createdby: survey.created.createdBy.name,
              formador: survey.formador ? survey.formador.name : '',
              location: survey.location ? survey.location.name : '',
              service: survey.service ? survey.service.name : '',
            };
          });
        });
      } catch (err) {}
    };
    fetchData();
  }, [
    sendRequest,
    serviceListToggle,
    surveyCtx.selectedSurvey,
    auth.token,
    filterJust,
    filterNegatives,
    selectedDates,
  ]);

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="center mt-2 mb-4">
        <RangePicker
          allowClear={false}
          defaultValue={selectedDates}
          locale={locale}
          onChange={handleDatesChange}
          style={{ marginRight: '10px' }}
        />
        <Select
          placeholder="Justificações"
          allowClear
          onChange={(value) => setFilterJust(value)}
          style={{ width: '175px', marginLeft: '10px' }}
        >
          <Option value={'includeJust'}>Com Justificações</Option>
          <Option value={'excludeJust'}>Sem Justificações</Option>
        </Select>
        <Checkbox
          style={{ marginLeft: '10px', color: 'white' }}
          onChange={(e) => setFilterNegatives(e.target.checked)}
          checked={filterNegatives}
          name="filterNegatives"
        >
          Negativos
        </Checkbox>
      </div>
      <DataGrid
        rows={rowData}
        columns={[
          {
            ...gridConfig.defaultColumn,
            headerName: 'Data',
            field: 'date',
            width: gridConfig.colWidth.date,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Criado por',
            field: 'createdby',
            width: gridConfig.colWidth.createdby,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Formador',
            field: 'formador',
            width: gridConfig.colWidth.formador,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Ação',
            field: 'service',
            width: gridConfig.colWidth.service,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Local',
            field: 'location',
            width: gridConfig.colWidth.service,
          },
        ]}
        render={() => setServiceListToggle(!serviceListToggle)}
      />
    </div>
  );
};

export default List_A2000_143;
