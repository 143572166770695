import React from 'react';
import HeaderDate from '../../../components/HeaderDate';
import HeaderText from '../../../components/HeaderText';
import MultiCheckbox from '../../../components/multi-checkbox';
import SubmitSurvey from '../../../components/SubmitSurvey';

import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';
import SurveyTextarea from '../../../components/survey-textarea';

import {
  A2000_098_QUESTIONS,
  A2000_098_ANSWERS,
  A2000_098_ANSWERS_Q5,
  A2000_098_ANSWERS_Q6,
  A2000_098_ANSWERS_Q7,
  A2000_098_ANSWERS_Q8,
} from './Questions_A2000_098';

import '../Survey.css';

const Survey_A2000_098 = (props) => {
  const { onChange, text } = props;

  const groupA = [];

  //group A
  for (let i = 1; i <= 4; i++) {
    groupA.push(
      <SurveyQuestion
        answers={A2000_098_ANSWERS}
        questions={A2000_098_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderText name="name" placeholder="Nome" />
          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup>
        {groupA}
        <SurveyTextarea
          id="sugestoes"
          onChange={onChange}
          placeholder="Caso avalie negativamente algum dos critérios justifique, por favor, ou se pretende abordar outras questões, ou apresentar sugestões para a melhoria da nossa Qualidade, utilize por favor este espaço:"
        />
      </SurveyGroup>
      <SurveyGroup>
        <MultiCheckbox
          num="5"
          onChange={onChange}
          answers={A2000_098_ANSWERS_Q5}
          questions={A2000_098_QUESTIONS}
          showOthers="Outras..."
        />
        <MultiCheckbox
          num="6"
          onChange={onChange}
          answers={A2000_098_ANSWERS_Q6}
          questions={A2000_098_QUESTIONS}
        />
        <MultiCheckbox
          num="7"
          onChange={onChange}
          answers={A2000_098_ANSWERS_Q7}
          questions={A2000_098_QUESTIONS}
          showOthers="Outras..."
        />
        <MultiCheckbox
          num="8"
          onChange={onChange}
          answers={A2000_098_ANSWERS_Q8}
          questions={A2000_098_QUESTIONS}
          showOthers="Outros..."
        />
      </SurveyGroup>
      <SurveyGroup>
        <SurveyTextarea
          id="gosto"
          onChange={onChange}
          rows="3"
          placeholder="O melhor da A2000 é ..."
        />
        <SurveyTextarea
          id="mudava"
          onChange={onChange}
          rows="3"
          placeholder="O que mudaria na A2000?"
        />
        <SubmitSurvey />
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_098;
