import React from 'react';

const TextCard = ({ title = '', answerList, totals, invalidAnswer = [] }) => {
  return (
    <div>
      <div className="center bg-dark mt-4">
        <p className="text-white h4">{title}</p>
      </div>
      <div className="center">
        <div className="text-white h6">
          <div>Total Respostas: {totals.validAnswers}</div>
          {answerList.map((ans) => {
            if (!invalidAnswer.includes(ans.text)) {
              return (
                <div key={ans.value}>
                  {ans.text + ': '}
                  {(
                    (totals[ans.text] / totals.validAnswers) * 100 || 0
                  ).toFixed(2)}
                  % {' (' + (totals[ans.text] || 0) + ')'}
                </div>
              );
            } else return <div key={ans.value}></div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default TextCard;
