import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';
import { A2000_143_ANSWERS, A2000_143_QUESTIONS } from './Questions_A2000_143';

import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import ChartByQuestion from '../charts/ChartByQuestion';
import QuestionTable from '../../../components/data/QuestionTable';
import TextCard from '../../../components/data/TextCard';
import SelectMultipleServices from '../../../components/SelectMultipleServices';
import SelectFormadorNew from '../../../components/SelectFormadorNew';

const Stats_A2000_143 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [surveyStats, setSurveyStats] = useState({});
  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);
  const [selectedServices, setSelectedServices] = useState({});
  const [selectedFormador, setSelectedFormador] = useState(null);

  const chartWidth = '100%';

  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_143/stats`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
            service: selectedServices,
            formador: selectedFormador,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setSurveyStats(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [
    sendRequest,
    auth.token,
    selectedDates,
    selectedServices,
    selectedFormador,
  ]);

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="center mt-2 mb-4">
          <RangePicker
            allowClear={false}
            defaultValue={selectedDates}
            locale={locale}
            onChange={handleDatesChange}
            style={{ marginRight: '10px' }}
          />
          <SelectMultipleServices
            onChange={(value) => setSelectedServices(value)}
          />
          <SelectFormadorNew onChange={(value) => setSelectedFormador(value)} />
        </div>
        {surveyStats.totals && (
          <div className="center">
            <p className="text-white h4">
              Número de avaliações: {surveyStats.totals.totalSurveys}
            </p>
          </div>
        )}
        {surveyStats.totals && surveyStats.totals.totalSurveys > 0 && (
          <div>
            <TextCard
              title="Questões 1 a 16"
              answerList={A2000_143_ANSWERS}
              totals={surveyStats.totals}
              invalidAnswer="Não se aplica / Não sei"
            />
            <QuestionTable
              questionStart={1}
              questionEnd={16}
              excludeQuestions={[]}
              questionsList={surveyStats.questions}
              totals={surveyStats.totals}
              questionsText={A2000_143_QUESTIONS}
              answerList={A2000_143_ANSWERS}
              invalidAnswer="Não se aplica / Não sei"
              useTextOnColumnNames
              answerWidth="auto"
              questionWidth="50%"
            ></QuestionTable>
            <ChartByQuestion
              title="Questões 1 a 16"
              height="450"
              selectedQuestions={[
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
              ]}
              answerList={A2000_143_ANSWERS}
              questionList={A2000_143_QUESTIONS}
              surveyStatsQuestions={surveyStats.questions}
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Stats_A2000_143;
