export const TABELA_NECESSIDADES_SOCIAIS = {
  educacao: 'Educação',
  emp_form_prof: 'Emprego e Formação Profissional',
  saude: 'Saúde',
  transportes: 'Transportes',
  dist_alimentar: 'Distribuição Alimentar',
  medicamentos: 'Medicamentos',
  tecnologias: 'Tecnologias',
  familia: 'Família',
  infancia: 'Infância',
  juventude: 'Juventude',
  idosos: 'Idosos',
  deficiencia: 'Deficiência',
  dependencias: 'Dependências (drogas, álcool)',
  doencamental: 'Doença Mental',
};

export const TABELA_NECESSIDADES_INFANCIA = [
  {
    key: 'INFANCIA_1',
    area: 'Infância',
    texto:
      'Acompanhamento de Crianças – regras básicas de nutrição higiene, segurança e repouso',
    horas: 50,
  },
  {
    key: 'INFANCIA_2',
    area: 'Infância',
    texto: 'Acompanhamento de criança – técnica de animação',
    horas: 50,
  },
  {
    key: 'INFANCIA_3',
    area: 'Infância',
    texto: 'Modelos e espaços pedagógicos',
    horas: 25,
  },
  {
    key: 'INFANCIA_4',
    area: 'Infância',
    texto: 'Comportamentos disfuncionais na criança – formas de atuação',
    horas: 50,
  },
  {
    key: 'INFANCIA_5',
    area: 'Infância',
    texto: 'Técnicas de animação',
    horas: 50,
  },
  {
    key: 'INFANCIA_6',
    area: 'Infância',
    texto:
      'Atividade pedagógica – acompanhamento, estudos e tempos livres da criança',
    horas: 50,
  },
  {
    key: 'INFANCIA_7',
    area: 'Infância',
    texto:
      'Atividades pedagógicas em creches e jardins-de-infância – quotidiano da criança',
    horas: 50,
  },
  {
    key: 'INFANCIA_8',
    area: 'Infância',
    texto: 'Modelos psicológicos e fases do desenvolvimento da criança',
    horas: 50,
  },
  {
    key: 'INFANCIA_9',
    area: 'Infância',
    texto:
      'Processo de comunicação e formas relacionais e pedagógicas da criança',
    horas: 50,
  },
  {
    key: 'INFANCIA_10',
    area: 'Infância',
    texto: 'Primeiros socorros - tipos de acidentes e formas de atuação',
    horas: 50,
  },
  {
    key: 'INFANCIA_11',
    area: 'Infância',
    texto: 'Oficina de expressão plástica',
    horas: 50,
  },
  {
    key: 'INFANCIA_12',
    area: 'Infância',
    texto: 'Assistência a crianças no domícilio – alimentação da criança',
    horas: 25,
  },
  {
    key: 'INFANCIA_13',
    area: 'Infância',
    texto:
      'Actividades pedagógicas – acompanhamento, estudos e tempos livres da criança',
    horas: 50,
  },
  {
    key: 'INFANCIA_14',
    area: 'Infância',
    texto: 'O jogo',
    horas: 25,
  },
  {
    key: 'INFANCIA_15',
    area: 'Infância',
    texto: 'Actividades pedagógicas do quotidiano da criança',
    horas: 25,
  },
  {
    key: 'INFANCIA_16',
    area: 'Infância',
    texto: 'Evolução e Desenvolvimento infantil',
    horas: 50,
  },
  {
    key: 'INFANCIA_17',
    area: 'Infância',
    texto: 'Higiene, saúde e segurança da criança',
    horas: 25,
  },
  {
    key: 'INFANCIA_18',
    area: 'Infância',
    texto: 'Planeamento e desenvolvimento de actividades de tempos livres',
    horas: 50,
  },
  {
    key: 'INFANCIA_19',
    area: 'Infância',
    texto: 'Actividades pedagógicas com crianças NEE',
    horas: 50,
  },
];

export const TABELA_NECESSIDADES_GERIATRIA = [
  {
    key: 'GERIATRIA_1',
    area: 'Geriatria',
    texto:
      'Ambiente, segurança, higiene e saúde no trabalho – conceitos básicos',
    horas: 25,
  },
  {
    key: 'GERIATRIA_2',
    area: 'Geriatria',
    texto: 'Animação no domicílio e em instituições – técnicas e atividades',
    horas: 25,
  },
  {
    key: 'GERIATRIA_3',
    area: 'Geriatria',
    texto: 'Saúde da pessoa idosa – cuidados básicos',
    horas: 25,
  },
  {
    key: 'GERIATRIA_4',
    area: 'Geriatria',
    texto: 'Animação no domicílio e em instituições – técnicas e atividades',
    horas: 50,
  },
  {
    key: 'GERIATRIA_5',
    area: 'Geriatria',
    texto: 'Higiene da pessoa idosa no domicílio',
    horas: 50,
  },
  {
    key: 'GERIATRIA_6',
    area: 'Geriatria',
    texto: 'Alimentação da pessoa idosa em lares e centros de dia',
    horas: 50,
  },
  {
    key: 'GERIATRIA_7',
    area: 'Geriatria',
    texto: 'Prevenção e primeiros socorros – geriatria',
    horas: 50,
  },
  {
    key: 'GERIATRIA_8',
    area: 'Geriatria',
    texto: 'Higiene da pessoa idosa em lares e centros de dia',
    horas: 50,
  },
];

export const TABELA_NECESSIDADES_ADMINISTRATIVA = [
  {
    key: 'ADMINISTRATIVA_1',
    area: 'Administrativa',
    texto: 'Imposto sobre o valor acrescentado (IVA) – direito fiscal',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_2',
    area: 'Administrativa',
    texto: 'Imposto sobre o rendimento (IRC)',
    horas: 50,
  },
  {
    key: 'ADMINISTRATIVA_3',
    area: 'Administrativa',
    texto: 'Documentação comercial – gestão económica das compras',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_4',
    area: 'Administrativa',
    texto: 'Função pessoal – legislação laboral',
    horas: 50,
  },
  {
    key: 'ADMINISTRATIVA_5',
    area: 'Administrativa',
    texto: 'Imposto sobre o rendimento (IRS) legislação fiscal',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_6',
    area: 'Administrativa',
    texto: 'Recursos humanos – processamento de vencimento',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_7',
    area: 'Administrativa',
    texto: 'Recursos humanos – balanço social',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_8',
    area: 'Administrativa',
    texto: 'Fundamentos de contabilidade (POC)',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_9',
    area: 'Administrativa',
    texto: 'Livros e mapas contabilísticos',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_10',
    area: 'Administrativa',
    texto: 'Plano oficial de contabilidade – contas e lançamentos',
    horas: 50,
  },
  {
    key: 'ADMINISTRATIVA_11',
    area: 'Administrativa',
    texto: 'Direito do trabalho',
    horas: 25,
  },
  {
    key: 'ADMINISTRATIVA_12',
    area: 'Administrativa',
    texto:
      'Rotinas de contabilidade –contas, escrituras comerciais e lançamentos',
    horas: 50,
  },
  {
    key: 'ADMINISTRATIVA_13',
    area: 'Administrativa',
    texto: 'Plano oficial de contabilidade – principais contas',
    horas: 25,
  },
];

export const TABELA_NECESSIDADES_AFAC = [
  {
    key: 'AFAC_1',
    area: 'Assistente familiar e Apoio à comunidade',
    texto: 'Higienização de espaços e equipamentos',
    horas: 50,
  },
  {
    key: 'AFAC_2',
    area: 'Assistente familiar e Apoio à comunidade',
    texto: 'Aquisição, armazenagem e conservação de produtos',
    horas: 50,
  },
  {
    key: 'AFAC_3',
    area: 'Assistente familiar e Apoio à comunidade',
    texto: 'Animação e lazer',
    horas: 50,
  },
  {
    key: 'AFAC_4',
    area: 'Assistente familiar e Apoio à comunidade',
    texto: 'Lavandaria e tratamento de roupa',
    horas: 50,
  },
  {
    key: 'AFAC_5',
    area: 'Assistente familiar e Apoio à comunidade',
    texto: 'Ética profissional e legislação laboral',
    horas: 50,
  },
  {
    key: 'AFAC_6',
    area: 'Assistente familiar e Apoio à comunidade',
    texto: 'Fundamentos gerais de segurança no trabalho',
    horas: 25,
  },
];

export const TABELA_NECESSIDADES_INFORMATICA = [
  {
    key: 'INFORMATICA_1',
    area: 'Informática',
    texto: 'Processador de texto – funcionalidades avançadas',
    horas: 25,
  },
  {
    key: 'INFORMATICA_2',
    area: 'Informática',
    texto: 'Folha de cálculo',
    horas: 50,
  },
  {
    key: 'INFORMATICA_3',
    area: 'Informática',
    texto: 'Sistemas de gestão de bases de dados (SGBD)',
    horas: 50,
  },
  {
    key: 'INFORMATICA_4',
    area: 'Informática',
    texto: 'Internet - navegação',
    horas: 25,
  },
];

export const TABELA_NECESSIDADES_COMERCIO = [
  {
    key: 'COMERCIO_1',
    area: 'Comércio',
    texto: 'Política de Gestão de Stocks',
    horas: 50,
  },
  {
    key: 'COMERCIO_2',
    area: 'Comércio',
    texto:
      'Ambiente, segurança, higiene e saúde no trabalho – conceitos básicos',
    horas: 25,
  },
  {
    key: 'COMERCIO_3',
    area: 'Comércio',
    texto: 'Perfil e funções do atendedor',
    horas: 25,
  },
  {
    key: 'COMERCIO_4',
    area: 'Comércio',
    texto: 'Atendimento',
    horas: 50,
  },
  {
    key: 'COMERCIO_5',
    area: 'Comércio',
    texto: 'Atendimento telefónico',
    horas: 25,
  },
  {
    key: 'COMERCIO_6',
    area: 'Comércio',
    texto: 'Reclamações – tratamento e encaminhamento',
    horas: 50,
  },
  {
    key: 'COMERCIO_7',
    area: 'Comércio',
    texto: 'Profissional de vendas – funções e competências',
    horas: 25,
  },
  {
    key: 'COMERCIO_8',
    area: 'Comércio',
    texto: 'Prospecção comercial, preparação e planeamento da venda',
    horas: 50,
  },
  {
    key: 'COMERCIO_9',
    area: 'Comércio',
    texto: 'Internet como estratégia de marketing',
    horas: 50,
  },
  {
    key: 'COMERCIO_10',
    area: 'Comércio',
    texto: 'Atendimento e venda presencial',
    horas: 25,
  },
  {
    key: 'COMERCIO_11',
    area: 'Comércio',
    texto: 'Gestão e motivação de equipas',
    horas: 25,
  },
];

export const TABELA_NECESSIDADES_TURISMO = [
  {
    key: 'TURISMO_1',
    area: 'Turismo',
    texto: 'Língua Inglesa - Informação',
    horas: 50,
  },
  {
    key: 'TURISMO_2',
    area: 'Turismo',
    texto: 'Atendimento e receção do cliente',
    horas: 50,
  },
  {
    key: 'TURISMO_3',
    area: 'Turismo',
    texto: 'Qualidade no serviço turístico',
    horas: 50,
  },
  {
    key: 'TURISMO_4',
    area: 'Turismo',
    texto:
      'Língua Inglesa – informações acerca da vida quotidiana, compras, serviços e locais de interesse turístico',
    horas: 50,
  },
  {
    key: 'TURISMO_5',
    area: 'Turismo',
    texto:
      'Língua Espanhola – informações acerca da vida quotidiana, compras, serviços e locais de interesse turístico',
    horas: 50,
  },
  {
    key: 'TURISMO_6',
    area: 'Turismo',
    texto: 'Animação turística',
    horas: 25,
  },
  {
    key: 'TURISMO_7',
    area: 'Turismo',
    texto: 'Técnicas de atendimento',
    horas: 25,
  },
];

export const TABELA_NECESSIDADES_HOTELARIA = [
  {
    key: 'HOTELARIA_1',
    area: 'Hotelaria',
    texto: 'Atendimentos de clientes na receção',
    horas: 50,
  },
  {
    key: 'HOTELARIA_2',
    area: 'Hotelaria',
    texto: 'Gestão de reclamações - hotelaria',
    horas: 25,
  },
  {
    key: 'HOTELARIA_3',
    area: 'Hotelaria',
    texto: 'Confeção de Saladas',
    horas: 25,
  },
  {
    key: 'HOTELARIA_4',
    area: 'Hotelaria',
    texto: 'Confeção de Sobremesas',
    horas: 25,
  },
  {
    key: 'HOTELARIA_5',
    area: 'Hotelaria',
    texto: 'Sistema HACCP',
    horas: 25,
  },
];
