import { createContext } from 'react';

export const SurveyContext = createContext({
  values: {},
  selectedSurvey: null,
  setValue: () => {},
  setValues: () => {},
  submitSurvey: () => {},
  changeView: () => {},
});
