import React, { useContext, useEffect } from 'react';
import { message } from 'antd';
import dateformat from 'dateformat';

//ag-grid
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import { useHttpClient } from '../../../shared/hooks/http-hook';

//contexts
import { SurveyContext } from '../../../shared/context/survey-context';
import { AuthContext } from '../../../shared/context/auth-context';

import GridButtons from './GridButtons';

const DataGrid = ({ rows, columns, render, showButtons = true }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const surveyCtx = useContext(SurveyContext);
  const auth = useContext(AuthContext);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dimensions]);

  const handleDelete = async (id) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/surveys/${surveyCtx.selectedSurvey}/${id}`,
        'DELETE',
        undefined,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.success) {
        message.success('Avaliação removida com sucesso');
        render();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = async (survey) => {
    const selSurvey = survey;
    console.log(survey);
    selSurvey.date = dateformat(survey.date, 'yyyy-mm-dd');
    surveyCtx.setValues(selSurvey);
    surveyCtx.changeView('new');
  };

  //add edit and delete buttons to the row
  if (showButtons)
    columns.push({
      headerName: '',
      width: 150,
      pinned: 'right',
      suppressMovable: true,
      cellRenderer: 'gridButtons',
      cellRendererParams: {
        editClick: handleEdit,
        deleteClick: handleDelete,
      },
    });

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: `${window.innerHeight - 220}px`,
        width: 'auto',
        marginTop: '0px',
        padding: '20px',
      }}
    >
      <AgGridReact
        rowData={rows}
        rowGroupPanelShow={'always'}
        rowSelection={true}
        gridOptions={{
          columnDefs: columns,
          frameworkComponents: {
            gridButtons: GridButtons,
          },
          statusBar: {
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left',
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default DataGrid;
