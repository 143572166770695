import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';
import {
  A2000_076_QUESTIONS,
  A2000_076_ANSWERS,
  A2000_076_ANSWERS_Q8,
  A2000_076_ANSWERS_Q9,
  A2000_076_ANSWERS_Q10,
  A2000_076_ANSWERS_Q11,
  A2000_076_ANSWERS_HEADER_2
} from './Questions_A2000_076';

import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import ChartByQuestion from '../charts/ChartByQuestion';
import QuestionTable from '../../../components/data/QuestionTable';
import TextCard from '../../../components/data/TextCard';
import ChartBySingleQuestion from '../charts/ChartBySingleQuestion';
import TextCardSingleQuestion from '../../../components/data/TextCardSingleQuestion';
import SelectMultipleServices from '../../../components/SelectMultipleServices';

const Stats_A2000_076 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [surveyStats, setSurveyStats] = useState({});
  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);
  const [selectedServices, setSelectedServices] = useState({});
  const [relacaoEscolhida, setRelacaoEscolhida] = useState(undefined);
  const [nivelRelacaoEscolhida, setNivelRelacaoEscolhida] = useState(undefined);

  const chartWidth = '100%';

  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_076/stats`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
            service: selectedServices,
            relacao: relacaoEscolhida,
            nivelRelacao: nivelRelacaoEscolhida,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setSurveyStats(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [
    sendRequest,
    auth.token,
    selectedDates,
    relacaoEscolhida,
    nivelRelacaoEscolhida,
    selectedServices,
  ]);

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="center mt-2 mb-4">
          <RangePicker
            allowClear={false}
            defaultValue={selectedDates}
            locale={locale}
            onChange={handleDatesChange}
            style={{ marginRight: '10px' }}
          />
          <SelectMultipleServices
            onChange={(value) => setSelectedServices(value)}
          />
          <Select
            placeholder="Relação"
            allowClear
            onChange={(value) => setRelacaoEscolhida(value)}
            style={{ width: '150px', marginLeft: '10px' }}
          >
            <Option value={'Parceiro'}>Parceiro</Option>
            <Option value={'Financiador'}>Financiador</Option>
            <Option value={'Fornecedor'}>Fornecedor</Option>
          </Select>
          <Select
            placeholder="Nivel Relação"
            allowClear
            onChange={(value) => setNivelRelacaoEscolhida(value)}
            style={{ width: '250px', marginLeft: '10px' }}
          >   
            {A2000_076_ANSWERS_HEADER_2.map(x => (
              <Option value={x.text}>{x.text}</Option>
            ))}
          </Select>
        </div>
        {surveyStats.totals && (
          <div className="center">
            <p className="text-white h4">
              Número de avaliações: {surveyStats.totals.totalSurveys}
            </p>
          </div>
        )}
        {surveyStats.totals && surveyStats.totals.totalSurveys > 0 && (
          <div>
            <TextCard
              title="Questões 1 a 7"
              answerList={A2000_076_ANSWERS}
              totals={surveyStats.totals}
              invalidAnswer="Não se Aplica"
            />
            <TextCardSingleQuestion
              title="Questão 8"
              answerList={A2000_076_ANSWERS_Q8}
              question={surveyStats.miscQuestions.Q08}
              invalidAnswer=""
            />
            <TextCardSingleQuestion
              title="Questão 9"
              answerList={A2000_076_ANSWERS_Q9}
              question={surveyStats.miscQuestions.Q09}
              invalidAnswer=""
            />
            <TextCardSingleQuestion
              title="Questão 10"
              answerList={A2000_076_ANSWERS_Q10}
              question={surveyStats.miscQuestions.Q10}
              invalidAnswer=""
            />
            <TextCardSingleQuestion
              title="Questão 11"
              answerList={A2000_076_ANSWERS_Q11}
              question={surveyStats.miscQuestions.Q11}
              invalidAnswer=""
            />
            <QuestionTable
              questionStart={1}
              questionEnd={7}
              excludeQuestions={[]}
              questionsList={surveyStats.questions}
              totals={surveyStats.totals}
              questionsText={A2000_076_QUESTIONS}
              answerList={A2000_076_ANSWERS}
              invalidAnswer="Não se Aplica"
              useTextOnColumnNames
              answerWidth="11%"
              questionWidth="34%"
            ></QuestionTable>
            <ChartByQuestion
              title="Questões 1 a 7"
              selectedQuestions={[1, 2, 3, 4, 5, 6, 7]}
              answerList={A2000_076_ANSWERS}
              questionList={A2000_076_QUESTIONS}
              surveyStatsQuestions={surveyStats.questions}
              ignoreAnswerThreshold="-1"
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="Questão 8"
              selectedQuestions={[8]}
              answerList={A2000_076_ANSWERS_Q8}
              questionList={A2000_076_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q08"
              largePalette
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="Questão 9"
              selectedQuestions={[9]}
              answerList={A2000_076_ANSWERS_Q9}
              questionList={A2000_076_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q09"
              largePalette
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="Questão 10"
              selectedQuestions={[10]}
              answerList={A2000_076_ANSWERS_Q10}
              questionList={A2000_076_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q10"
              largePalette
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="Questão 11"
              selectedQuestions={[11]}
              answerList={A2000_076_ANSWERS_Q11}
              questionList={A2000_076_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q11"
              largePalette
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Stats_A2000_076;
