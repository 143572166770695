import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';
import QuestionHeader from './question-header';
import { Radio, Row } from 'antd';

const SurveyQuestion = (props) => {
  const {
    num,
    questions,
    answers,
    isSelect,
    customHeader,
    width,
    selectText,
    name,
  } = props;

  const questionName = num ? 'Q' + num.toString().padStart(2, '0') : 'default';

  const surveyCtx = useContext(SurveyContext);

  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value);
  };

  const selectInitialText = selectText || 'Escolha:';

  const header = questions ? (
    <QuestionHeader questionNum={num}>{questions[num]}</QuestionHeader>
  ) : (
    customHeader && <QuestionHeader>{customHeader}</QuestionHeader>
  );

  return !isSelect ? (
    <div className="m-4">
      {header}
      <Row className="ml-3">
        <Radio.Group
          value={surveyCtx.values[questionName]}
          onChange={onChangeHandler}
          name={questionName}
          buttonStyle="solid"
        >
          {answers.map((item) => {
            return (
              <Radio.Button key={item.text} value={item.text}>
                {item.text}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </Row>
    </div>
  ) : (
    <div className="m-4">
      {header}
      <div className="ml-1 row">
        <select
          onChange={onChangeHandler}
          className="form-control form-check-input"
          id={name ? name : questionName}
          name={name ? name : questionName}
          value={surveyCtx.values[name ? name : questionName] || ''}
          style={{ width: width || '200px' }}
        >
          <option value="" disabled>
            {selectInitialText}
          </option>
          {answers.map((item) => {
            return (
              <option key={`${num}_${item.value}`} value={item.text}>
                {item.text}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SurveyQuestion;
