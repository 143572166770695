import React, { useEffect, useState, useContext } from 'react';
import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker, Select, Checkbox } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';

import DataGrid from '../../../components/data/DataGrid';
import { gridConfig } from '../common/config';

const List_A2000_070 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [rowData, setRowData] = useState([]);

  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);
  const [filterJust, setFilterJust] = useState('');
  const [filterNegatives, setFilterNegatives] = useState(false);
  const [filterGosto, setFilterGosto] = useState(false);
  const [filterMudava, setFilterMudava] = useState(false);
  const [serviceListToggle, setServiceListToggle] = useState(false);
  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  function onChangeJust(value) {
    setFilterJust(value);
  }

  function onChangeNegative(e) {
    switch (e.target.name) {
      case 'filterNegatives':
        setFilterNegatives(e.target.checked);
        break;
      case 'filterGosto':
        setFilterGosto(e.target.checked);
        break;
      case 'filterMudava':
        setFilterMudava(e.target.checked);
        break;
      default:
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_070/filtered`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
            justifications: filterJust,
            negatives: filterNegatives,
            gosto: filterGosto,
            mudava: filterMudava,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setRowData(() => {
          return responseData.data.map((survey) => {
            return {
              survey: survey,
              date: new Date(survey.date).toLocaleDateString(),
              createdby: survey.created.createdBy.name,
              service: survey.service.name,
              subject: survey.subject,
              name: survey.name,
              just_A: survey.just_A ? survey.just_A.replace(/\n|\r/g, ' ') : '',
              just_B: survey.just_B ? survey.just_B.replace(/\n|\r/g, ' ') : '',
              just_C: survey.just_C ? survey.just_C.replace(/\n|\r/g, ' ') : '',
              just_D: survey.just_D ? survey.just_D.replace(/\n|\r/g, ' ') : '',
              gosto: survey.gosto ? survey.gosto.replace(/\n|\r/g, ' ') : '',
              mudava: survey.mudava ? survey.mudava.replace(/\n|\r/g, ' ') : '',
              comentarios: survey.comentarios
                ? survey.comentarios.replace(/\n|\r/g, ' ')
                : '',
            };
          });
        });
      } catch (err) {}
    };
    fetchData();
  }, [
    sendRequest,
    serviceListToggle,
    auth.token,
    selectedDates,
    filterJust,
    filterNegatives,
    filterGosto,
    filterMudava,
  ]);

  return (
    <div style={{ width: '100%' }}>
      <div className="center mt-2 mb-4">
        <RangePicker
          allowClear={false}
          defaultValue={selectedDates}
          locale={locale}
          onChange={handleDatesChange}
          style={{ marginRight: '10px' }}
        />
        <Select
          placeholder="Justificações"
          allowClear
          onChange={onChangeJust}
          style={{ width: '175px', marginLeft: '10px' }}
        >
          <Option value={'includeJust'}>Com Justificações</Option>
          <Option value={'excludeJust'}>Sem Justificações</Option>
        </Select>
        <Checkbox
          style={{ marginLeft: '10px', color: 'white' }}
          onChange={onChangeNegative}
          checked={filterNegatives}
          name="filterNegatives"
        >
          Negativos
        </Checkbox>
        <Checkbox
          style={{ marginLeft: '10px', color: 'white' }}
          onChange={onChangeNegative}
          checked={filterGosto}
          name="filterGosto"
        >
          Questão 33 (O que gosto mais na A2000)
        </Checkbox>
        <Checkbox
          style={{ marginLeft: '10px', color: 'white' }}
          onChange={onChangeNegative}
          checked={filterMudava}
          name="filterMudava"
        >
          Questão 34 (O que mudava na A2000)
        </Checkbox>
      </div>
      <DataGrid
        rows={rowData}
        columns={[
          {
            ...gridConfig.defaultColumn,
            headerName: 'Data',
            field: 'date',
            width: gridConfig.colWidth.date,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Criado por',
            field: 'createdby',
            width: gridConfig.colWidth.createdby,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Serviço',
            field: 'service',
            width: gridConfig.colWidth.service,
            filter: true,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Cliente/Significativo',
            field: 'subject',
            filter: true,
          },
          {
            ...gridConfig.defaultColumn,
            headerName: 'Nome',
            field: 'name',
            width: gridConfig.colWidth.name,
          },
          {
            headerName: 'Justificações',
            children: [
              {
                headerName: '',
                columnGroupShow: 'closed',
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Justificação A',
                field: 'just_A',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Justificação B',
                field: 'just_B',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Justificação C',
                field: 'just_C',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Justificação D',
                field: 'just_D',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Gosto',
                field: 'gosto',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Mudava',
                field: 'mudava',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
              {
                ...gridConfig.defaultColumn,
                headerName: 'Comentários',
                field: 'comentarios',
                columnGroupShow: 'open',
                width: gridConfig.colWidth.comment,
              },
            ],
          },
        ]}
        render={() => setServiceListToggle(!serviceListToggle)}
      />
    </div>
  );
};

export default List_A2000_070;
