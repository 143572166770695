import React from 'react';
import HeaderDate from '../../../components/HeaderDate';
import HeaderText from '../../../components/HeaderText';
import MultiCheckbox from '../../../components/multi-checkbox';

import SurveyGroup from '../../../components/survey-group';
import HeaderService from '../../../components/HeaderService';
import SurveyQuestion from '../../../components/survey-question';
import SurveyTextarea from '../../../components/survey-textarea';
import SubmitSurvey from '../../../components/SubmitSurvey';

import {
  A2000_076_QUESTIONS,
  A2000_076_ANSWERS,
  A2000_076_ANSWERS_HEADER_1,
  A2000_076_ANSWERS_HEADER_2,
  A2000_076_ANSWERS_Q8,
  A2000_076_ANSWERS_Q9,
  A2000_076_ANSWERS_Q10,
  A2000_076_ANSWERS_Q11,
} from './Questions_A2000_076';

import '../Survey.css';

const Survey_A2000_076 = (props) => {
  const { onChange, text } = props;

  const groupA = [];

  //group A
  for (let i = 1; i <= 7; i++) {
    groupA.push(
      <SurveyQuestion
        answers={A2000_076_ANSWERS}
        questions={A2000_076_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderService />
          <HeaderText name="entity" placeholder="Entidade" />

          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup name="Relação Institucional">
        <MultiCheckbox
          customHeader="Que tipo de relação mantém com a Instituição?"
          num="header_1"
          onChange={onChange}
          answers={A2000_076_ANSWERS_HEADER_1}
          showOthers="Outras..."
        />
        <MultiCheckbox
          customHeader="As relações de parceria são a que nível?"
          num="header_2"
          onChange={onChange}
          answers={A2000_076_ANSWERS_HEADER_2}
          showOthers="Outro. Qual?"
        />
      </SurveyGroup>
      <SurveyGroup>
        {groupA}
        <SurveyTextarea
          id="sugestoes"
          onChange={onChange}
          rows="5"
          placeholder="Caso avalie negativamente algum dos critérios justifique, por favor, ou se pretende abordar outras questões, ou apresentar sugestões para a melhoria da nossa Qualidade, utilize por favor este espaço:"
        />
      </SurveyGroup>
      <SurveyGroup>
        <MultiCheckbox
          num="8"
          onChange={onChange}
          answers={A2000_076_ANSWERS_Q8}
          questions={A2000_076_QUESTIONS}
          showOthers="Outras..."
        />
        <MultiCheckbox
          num="9"
          onChange={onChange}
          answers={A2000_076_ANSWERS_Q9}
          questions={A2000_076_QUESTIONS}
        />
        <MultiCheckbox
          num="10"
          onChange={onChange}
          answers={A2000_076_ANSWERS_Q10}
          questions={A2000_076_QUESTIONS}
          showOthers="Outras..."
        />
        <MultiCheckbox
          num="11"
          onChange={onChange}
          answers={A2000_076_ANSWERS_Q11}
          questions={A2000_076_QUESTIONS}
          showOthers="Outros"
        />
      </SurveyGroup>
      <SurveyGroup>
        <SurveyTextarea
          id="melhor"
          onChange={onChange}
          rows="3"
          placeholder="Qual o melhor desta relação institucional?"
        />
        <SurveyTextarea
          id="mudaria"
          onChange={onChange}
          rows="3"
          placeholder="O que mudaria nesta relação institucional?"
        />
        <SubmitSurvey />
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_076;
