export const A2000_082_QUESTIONS = {
  //CAPACITAÇÃO - A
  1: 'O nº de ações de formação realizadas foi satisfatória',
  2: 'A qualidade das ações / formadores foi boa',
  3: 'Os resultados obtidos foram satisfatórios',
  4: 'Colaborar na A2000 é fator de desenvolvimento profissional e/ou pessoa',

  //MOTIVAÇÃO - B
  5: 'Gosto das tarefas/funções que desempenho',
  6: 'Considero os objetivos da A2000 importantes',
  7: 'O que mais me motiva? – Escolha DUAS respostas: uma que “Concorda Plenamente” e outra que “Concorda”',
  8: 'Quando me são exigidos maiores esforços, sinto-me igualmente satisfeito',
  9: 'As promoções são realizadas com base na competência e produtividade',
  10: 'A remuneração é adequada e justa',

  //CONDIÇÕES DE TRABALHO - C
  11: 'O meu posto de trabalho (Instalações/ Equipamentos) é adequado às necessidades da minha função',
  12: 'O horário de trabalho é adequado',
  13: 'O volume de trabalho é adequado',
  14: 'A responsabilidade exigida é adequada',
  15: 'As Metodologias de trabalho/Equipa são eficazes e adequada',

  //DESCENTRALIZAÇÃO - D
  16: 'Tenho autonomia no desempenho da minha função',
  17: 'É permitido que eu seja criativo(a) e tome iniciativa',
  18: 'Posso mudar metodologias na execução das minhas funções',
  19: 'A minha participação é solicitada nas decisões de equipa',
  20: 'As minhas sugestões profissionais são aceites e implementadas',
  21: 'Participo na Planificação dos serviços em que colaboro',
  22: 'Tenho acesso a toda a informação que preciso para a realização do meu trabalho',
  23: 'Dão-me feedback sobre o meu desempenho',
  24: 'Tenho confiança nos colaboradores e nos líderes',

  //AMBIENTE RELACIONAL - E
  25: 'Tenho liberdade de expressão de opinião face aos meus superiores diretos',
  26: 'A comunicação interna, ao nível profissional, flui de modo eficaz',
  27: 'Há cooperação entre os meus colegas e eu',
  28: 'Sinto que o meu trabalho é reconhecido pelos meus superiores',
  29: 'Sinto que o meu trabalho é reconhecido pelos meus colegas',
  30: 'Tenho bom relacionamento com os clientes',
  31: 'Tenho bom relacionamento com os parceiros',
  32: 'Gosto muito de trabalhar com este público-alvo',

  //REALIZAÇÃO PESSOAL - F
  33: 'O trabalho dá-me satisfação pessoal',
  34: 'Sinto-me confiante no desempenho da minha função',
  35: 'A/s área/s onde colaboro presta/m um serviço de qualidade',
  36: 'O nível de Stress profissional é razoável/suportável',
  37: 'Se não é suportável, quais os DOIS principais motivos?',

  //MUDANÇA - G
  38: 'Gosto quando ocorrem mudanças organizacionais (na minha função, metodologias de trabalho, objetivos, etc.)',
  39: 'Quando ocorrem mudanças (na minha função/ posto de trabalho, metodologias, objetivos, etc.) sinto-me (escolha UMA resposta)',

  //TRABALHO E ESPAÇO DE VIDA - H
  40: 'Levo trabalhos para finalizar em casa?',
  41: 'O meu trabalho interfere com a minha vida familiar?',
  42: 'Tenho tempo disponível para lazer e atividades sociais (pois o trabalho não interfere nessa área)',
  43: 'Realizo horas extras?',
  44: 'Sinto orgulho em dizer onde trabalho',
};

export const A2000_082_ANSWERS = [
  { value: '4', text: 'Concordo Plenamente' },
  { value: '3', text: 'Concordo' },
  { value: '2', text: 'Concordo Pouco' },
  { value: '1', text: 'Não Concordo' },
  { value: '0', text: 'Não se Aplica' },
];

export const A2000_082_ANSWERS_Q7 = [
  { value: '1', text: 'Reconhecimento público do meu trabalho' },
  { value: '2', text: 'Reconhecimento do meu chefe' },
  { value: '3', text: 'Reconhecimento dos meus colegas' },
  { value: '4', text: 'Os resultados do meu trabalho' },
  { value: '5', text: 'A satisfação dos clientes' },
  { value: '6', text: 'O reconhecimento dos clientes' },
  { value: '7', text: 'O aumento salarial' },
  { value: '8', text: 'Os incentivos definidos para o triénio' },
  { value: '9', text: 'A capacitação profissional/pessoal' },
  { value: '10', text: 'O ambiente de trabalho' },
];

export const A2000_082_ANSWERS_Q37 = [
  {
    value: '1',
    text: 'Falta de clareza nas regras, normas e tarefas que desempenho',
  },
  { value: '2', text: 'Grande pressão de datas prazos' },
  { value: '3', text: 'Grande desgaste psicológico' },
  { value: '4', text: 'Grande volume de serviço' },
  { value: '5', text: 'Ter que desempenhar múltiplos serviços em simultâneo' },
  { value: '6', text: 'Falta de equipamentos adequados' },
  { value: '7', text: 'Relações interpessoais' },
];

export const A2000_082_ANSWERS_Q39 = [
  { value: '1', text: 'Stressado' },
  { value: '2', text: 'Desrespeitado' },
  { value: '3', text: 'Satisfeito' },
  { value: '4', text: 'Indiferente' },
  { value: '0', text: 'Não se Aplica' },
];

export const A2000_082_ANSWERS_2 = [
  { value: 4, text: 'Sempre' },
  { value: 3, text: 'Moderadamente' },
  { value: 2, text: 'Raramente' },
  { value: 1, text: 'Nunca' },
  { value: 0, text: 'Não se Aplica' },
];
