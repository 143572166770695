import React, { useContext } from 'react';
import { SurveyContext } from '../../../../shared/context/survey-context';

const Tabela2 = ({ dadosTabela, slug } = []) => {
  const surveyCtx = useContext(SurveyContext);

  return (
    <table className="table table-bordered mt-4">
      <thead className="thead-dark">
        <tr className="text-center">
          <th colSpan="3">{dadosTabela[0].area.toUpperCase()}</th>
        </tr>
        <tr className="text-center">
          <th className="align-middle" scope="col" width="80%">
            Designação
          </th>
          <th className="align-middle" scope="col" width="10%">
            Nº Horas
          </th>
          <th scope="col center" width="5%"></th>
        </tr>
      </thead>
      <tbody>
        {dadosTabela.map((entry, index) => {
          return (
            <tr key={index}>
              <td>{entry.texto}</td>
              <td className="text-center">{entry.horas}</td>
              <td className="text-center center">
                <input
                  name={entry.key}
                  type="checkbox"
                  className="form-control"
                  style={{ width: '20px', height: '20px' }}
                  value={
                    surveyCtx.values.tabela2
                      ? surveyCtx.values.tabela2[entry.key]
                      : undefined
                  }
                  checked={
                    surveyCtx.values.tabela2
                      ? surveyCtx.values.tabela2[entry.key]
                      : undefined
                  }
                  onClick={(e) => {
                    let value = { ...surveyCtx.values.tabela2 } || {};

                    value[e.target.name] = e.target.checked;

                    surveyCtx.setValue('tabela2', value || {});
                  }}
                />
              </td>
            </tr>
          );
        })}
        <tr>
          <td>
            <input
              type="text"
              className="form-control"
              placeholder="Outra"
              value={surveyCtx.values[slug + '_OUTRA_DESC'] || ''}
              onChange={(e) => {
                let tmpValues = { ...surveyCtx.values };

                if (!tmpValues.tabela2) tmpValues.tabela2 = {};

                tmpValues[slug + '_OUTRA_DESC'] = e.target.value;
                tmpValues.tabela2[slug + '_OUTRA'] = e.target.value
                  ? true
                  : false;

                surveyCtx.setValues(tmpValues);
              }}
            />
          </td>
          <td colSpan="2" className="text-center">
            <input
              type="text"
              className="form-control"
              placeholder="Horas"
              value={surveyCtx.values[slug + '_OUTRA_HORAS'] || ''}
              onChange={(e) =>
                surveyCtx.setValue(slug + '_OUTRA_HORAS', e.target.value)
              }
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Tabela2;
