import React from 'react';
import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';

import '../Survey.css';
import { A2000_070_QUESTIONS, A2000_070_ANSWERS } from './Questions_A2000_070';
import SurveyTextarea from '../../../components/survey-textarea';
import HeaderDate from '../../../components/HeaderDate';
import HeaderService from '../../../components/HeaderService';
import HeaderSelect from '../../../components/HeaderSelect';
import HeaderText from '../../../components/HeaderText';
import SubmitSurvey from '../../../components/SubmitSurvey';

const Survey_A2000_070 = ({ onChange, text }) => {
  const groupA = [],
    groupB = [],
    groupC = [],
    groupD = [];

  //groupA = Contextos
  for (let i = 1; i <= 6; i++) {
    groupA.push(
      <SurveyQuestion
        answers={A2000_070_ANSWERS}
        questions={A2000_070_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }
  //groupB = Direitos do Cliente
  for (let i = 7; i <= 11; i++) {
    groupB.push(
      <SurveyQuestion
        answers={A2000_070_ANSWERS}
        questions={A2000_070_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //groupC = Participação
  for (let i = 12; i <= 23; i++) {
    groupC.push(
      <SurveyQuestion
        answers={A2000_070_ANSWERS}
        questions={A2000_070_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //groupD = Apoio da Equipa Técnica
  for (let i = 24; i <= 30; i++) {
    groupD.push(
      <SurveyQuestion
        answers={A2000_070_ANSWERS}
        questions={A2000_070_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderService />
          <HeaderSelect
            options={[
              { value: 'cliente', text: 'Cliente' },
              { value: 'significativo', text: 'Significativo' },
            ]}
            name="subject"
            placeholder="Cliente/Significativo"
          />
          <HeaderText name="name" placeholder="Nome" />
          <SubmitSurvey inline />
        </div>
      </div>

      <SurveyGroup name="A - Contextos">
        {groupA}
        <SurveyTextarea
          id="just_A"
          onChange={onChange}
          placeholder="Justifique as avaliações negativas (pouco ou nada satisfeito)"
        />
      </SurveyGroup>
      <SurveyGroup name="B - Direitos do Cliente">
        {groupB}
        <SurveyTextarea
          id="just_B"
          onChange={onChange}
          placeholder="Justifique as avaliações negativas (pouco ou nada satisfeito)"
        />
      </SurveyGroup>
      <SurveyGroup name="C - Participação">
        {groupC}
        <SurveyTextarea
          id="just_C"
          onChange={onChange}
          placeholder="Justifique as avaliações negativas (pouco ou nada satisfeito)"
        />
      </SurveyGroup>
      <SurveyGroup name="D - Apoio da Equipa Técnica">
        {groupD}
        <SurveyTextarea
          id="just_D"
          onChange={onChange}
          placeholder="Justifique as avaliações negativas (pouco ou nada satisfeito)"
        />
      </SurveyGroup>
      <SurveyGroup name="E - Avaliação Geral">
        <SurveyQuestion
          answers={A2000_070_ANSWERS}
          questions={A2000_070_QUESTIONS}
          onChange={onChange}
          num="31"
        />
        <SurveyQuestion
          answers={A2000_070_ANSWERS}
          questions={A2000_070_QUESTIONS}
          onChange={onChange}
          num="32"
        />
      </SurveyGroup>
      <SurveyGroup>
        <SurveyTextarea
          id="gosto"
          onChange={onChange}
          rows="3"
          placeholder="O que gosto mais na A2000"
        />
        <SurveyTextarea
          id="mudava"
          onChange={onChange}
          rows="3"
          placeholder="O que mudava na A2000"
        />
        <SurveyTextarea
          id="comentarios"
          onChange={onChange}
          rows="6"
          placeholder="Outros comentários / sugestões relativas ao serviço prestado"
        />
        <SubmitSurvey />
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_070;
