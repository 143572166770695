import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useHttpClient } from '../../shared/hooks/http-hook';

const SelectMultipleLocations = ({ onChange }) => {
  const [loadedData, setLoadedData] = useState([]);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { Option } = Select;

  useEffect(() => {
    const fetchUserPlaces = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/locations?select=name&sort=name`
        );

        setLoadedData(responseData.data);
      } catch (err) {}
    };
    fetchUserPlaces();
  }, [sendRequest]);

  return (
    <div className="w-50 ml-2">
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Local"
        //defaultValue={['a10', 'c12']}
        onChange={onChange}
      >
        {loadedData.map((data, key) => {
          return (
            <Option key={key} value={data._id}>
              {data.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectMultipleLocations;
