import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';

import SelectMultipleServices from '../components/SelectMultipleServices';
import SelectFormadorNew from '../components/SelectFormadorNew';

import ChartDesempenhoFormador from './surveys/charts/ChartDesempenhoFormador';

const DesempenhoFormador = () => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [desempenhoFormadores, setDesempenhoFormadores] = useState({});
  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);
  const [selectedServices, setSelectedServices] = useState({});
  const [selectedFormador, setSelectedFormador] = useState(null);

  const chartWidth = '100%';

  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/desempenhoformadores`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
            service: selectedServices,
            formador: selectedFormador,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );

        setDesempenhoFormadores(responseData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [
    sendRequest,
    auth.token,
    selectedDates,
    selectedServices,
    selectedFormador,
  ]);

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="center mt-2 mb-4">
          <RangePicker
            allowClear={false}
            defaultValue={selectedDates}
            locale={locale}
            onChange={handleDatesChange}
            style={{ marginRight: '10px' }}
          />
          <SelectMultipleServices
            onChange={(value) => setSelectedServices(value)}
          />
          <SelectFormadorNew onChange={(value) => setSelectedFormador(value)} />
        </div>
        {desempenhoFormadores && (
          <div>
            <div className="center">
              <p className="text-white h4">
                Número de avaliações 141: {desempenhoFormadores.total141 || 0}
              </p>
            </div>
            <div className="center">
              <p className="text-white h4">
                Número de avaliações 142: {desempenhoFormadores.total142 || 0}
              </p>
            </div>
            <div className="center">
              <p className="text-white h4">
                Número de avaliações 143: {desempenhoFormadores.total143 || 0}
              </p>
            </div>
            <div className="bg-white">
              <ChartDesempenhoFormador desempenhoArray={desempenhoFormadores} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesempenhoFormador;
