export const A2000_165_QUESTIONS = {
  1: 'Espaço (dimensão, temperatura, humidade, etc.)',
  2: 'Mobiliário',
  3: 'Os equipamentos são adequados às atividades',
  4: 'As instalações são limpas e arrumadas',
  5: 'No início da ação fui informado sobre o cronograma, o horário e os objetivos/ conteúdos da formação',
  6: 'A ação decorreu de acordo com o que inicialmente estava à espera',
  7: 'Os temas abordados foram úteis para a vida pessoal dos formandos',
  8: 'Os temas abordados foram úteis para a vida profissional dos formandos',
  9: 'As atividades foram muito importantes ',
  10: 'A carga horária revelou-se adequada',
  11: 'Tenho conhecimento da existência do Regulamento Interno, ao qual tenho acesso',
  12: 'Os colaboradores revelaram-se disponíveis quando preciso',
  13: 'Sempre que eu reclamo/sugiro em documento próprio, dão-me resposta',
  14: 'O pagamento dos honorários ocorreu na data prevista. (Apenas para formadores externos)',
  15: 'Considero que o serviço prestado tem qualidade',
  16: 'De uma forma geral, qual o seu grau de satisfação com a Associação?',
  17: 'Se um amigo seu precisasse, recomendaria a A2000?',
  18: 'Se pudesse, mudaria de organização?',
};

export const A2000_165_ANSWERS = [
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '0', text: 'Não se aplica' },
];

export const A2000_165_ANSWERS_2 = [
  { value: '1', text: 'Sim' },
  { value: '2', text: 'Não' },
];
