import React from 'react';

const TextCardSingleQuestion = ({
  title = '',
  answerList,
  question,
  invalidAnswer = [],
  customTotal = null,
}) => {
  return (
    <div>
      <div className="center bg-dark mt-4">
        <p className="text-white h4">{title}</p>
      </div>
      <div className="center">
        <div className="text-white h6">
          <div>
            Total Respostas:{' '}
            {customTotal ? customTotal : question.totals.validAnswers}
          </div>
          {answerList.map((ans) => {
            if (!invalidAnswer.includes(ans.text)) {
              return (
                <div key={ans.value}>
                  {ans.text + ': '}
                  {(
                    (question[ans.text] /
                      (customTotal
                        ? customTotal
                        : question.totals.validAnswers)) *
                      100 || 0
                  ).toFixed(2)}
                  % {' (' + (question[ans.text] || 0) + ')'}
                </div>
              );
            } else return <div></div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default TextCardSingleQuestion;
