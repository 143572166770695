import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles, Container } from '@material-ui/core';
import { useHttpClient } from '../shared/hooks/http-hook';

const Indicadores = () => {
  const [indicadores, setIndicadores] = useState([]);

  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/indicadores`
        );
        console.log(responseData);
        setIndicadores(responseData);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest]);

  const columns = [
    { field: 'id', headerName: '#', width: 75 },
    { field: 'indicador', headerName: 'Indicador', width: 750 },
    { field: 'impresso', headerName: 'Impresso', width: 100 },
    { field: 'valor1', headerName: 'Total', width: 100 },
    { field: 'valor2', headerName: 'Satisfação', width: 110 },
  ];

  const rows = [
    {
      id: 21,
      indicador:
        'Taxa de satisfação dos clientes com o respeito pelo seus direitos, valores e crenças',
      impresso: '070',
      valor1: indicadores.indicador_21
        ? indicadores.indicador_21.toFixed(2) + '%'
        : '',
      valor2: indicadores.indicador_21_satisfacao
        ? indicadores.indicador_21_satisfacao.toFixed(2) + '%'
        : '',
    },
    {
      id: 22,
      indicador:
        'Taxa de satisfação de clientes / significativos com as questões de ética',
      impresso: '070',
      valor1: indicadores.indicador_22
        ? indicadores.indicador_22.toFixed(2) + '%'
        : '',
      valor2: indicadores.indicador_22_satisfacao
        ? indicadores.indicador_22_satisfacao.toFixed(2) + '%'
        : '',
    },
    {
      id: 23,
      indicador: 'Taxa de utilidade/aplicabilidade da formação na vida da PCDI',
      impresso: '145',
      valor1: indicadores.indicador_23
        ? indicadores.indicador_23.toFixed(2) + '%'
        : '',
    },
    {
      id: 24,
      indicador:
        'Taxa de satisfação quanto ao desenvolvimento de novas competências',
      impresso: '070',
      valor1: indicadores.indicador_24
        ? indicadores.indicador_24.toFixed(2) + '%'
        : '',
      valor2: indicadores.indicador_24_satisfacao
        ? indicadores.indicador_24_satisfacao.toFixed(2) + '%'
        : '',
    },
    {
      id: 25,
      indicador:
        'Taxa de satisfação com o empowerment adquirido',
      impresso: '070',
      valor1: indicadores.indicador_25
        ? indicadores.indicador_25.toFixed(2) + '%'
        : '',
      valor2: indicadores.indicador_25_satisfacao
        ? indicadores.indicador_25_satisfacao.toFixed(2) + '%'
        : '',
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    container: {
      marginTop: '20px',
      height: '40px',
      backgroundColor: 'black',
    },
    grid: {
      //backgroundColor: 'purple',
      padding: '2px',
    },
    button: {
      margin: '10px',
    },
  }));

  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div
        style={{
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          className={classes.root}
        />
      </div>
    </Container>
  );
};

export default Indicadores;
