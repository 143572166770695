import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

const SubmitSurvey = ({ inline = false, text = 'Guardar Avaliação' }) => {
  const surveyCtx = useContext(SurveyContext);

  return inline ? (
    <div className="col-xs-2 m-2">
      <button
        onClick={surveyCtx.submitSurvey}
        className="btn btn-success"
        style={{ width: '175px' }}
      >
        {text}
      </button>
    </div>
  ) : (
    <div className="pr-4 pl-4 mb-4">
      <button
        onClick={surveyCtx.submitSurvey}
        className="form-control btn btn-success"
      >
        {text}
      </button>
    </div>
  );
};

export default SubmitSurvey;
