import React from 'react';
import { Popconfirm } from 'antd';

const GridButtons = (props) => {
  if (!props.data) return <div></div>;

  return (
    <div className="btn-group btn-group-sm" role="group">
      <button
        onClick={() => props.editClick(props.data.survey)}
        className="btn btn-outline-primary"
      >
        Editar
      </button>
      <Popconfirm
        title={
          <div>
            <p>Deseja eliminar esta avaliação?</p>
          </div>
        }
        onConfirm={() => props.deleteClick(props.data.survey._id)}
        okText="Sim"
        cancelText="Não"
      >
        <button className="btn btn-outline-danger">Eliminar</button>
      </Popconfirm>
    </div>
  );
};

export default GridButtons;
