import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

const HeaderText = ({
  name = 'text',
  placeholder = 'Texto',
  style = { width: '225px' },
}) => {
  const surveyCtx = useContext(SurveyContext);
  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value || '');
  };
  return (
    <div className="col-xs-2 form-inline m-2">
      <input
        onChange={onChangeHandler}
        type="text"
        placeholder={placeholder}
        value={surveyCtx.values[name] || ''}
        id={name}
        name={name}
        className="form-control"
        style={style}
      ></input>
    </div>
  );
};

export default HeaderText;
