import React, { useContext, useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import './NavLinks.css';

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/year`
        );

        setSelectedYear(responseData.year);

      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, selectedYear]);

  const changeYear = async (event , changeYear = "2022") => {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/changeyear/` + event.target.value
    );
    setSelectedYear(changeYear);
    history.push('/');
  }

  return (
    <ul className="nav-links">
      
      {(auth.isLoggedIn && (process.env.REACT_APP_BACKUP === "true")) &&(
        <li>
          <select className="form-control-sm" onChange={changeYear} select={selectedYear} value={selectedYear}>
            <option>2021</option>
            <option>2020</option>
          </select>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/desempenhoformador">DESEMPENHO FORMADOR</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/indicadores">INDICADORES</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/services">SERVIÇOS</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/locations">LOCAIS</NavLink>
        </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <NavLink to="/surveys">AVALIAÇÕES</NavLink>
        </li>
      )}

      {auth.isLoggedIn && false && (
        <li>
          <NavLink to={`/${auth.userId}/places`}>MY PLACES</NavLink>
        </li>
      )}

      {auth.isLoggedIn && false && (
        <li>
          <NavLink to="/places/new">ADD PLACE</NavLink>
        </li>
      )}

      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/login">ENTRAR</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (process.env.REACT_APP_BACKUP === "false") &&(
        <li>
          <a className="ml-4 mr-4 btn btn-sm btn-info" href="https://backup.a2000.pt">
            BACKUP
          </a>
        </li>
      )}
      {auth.isLoggedIn && (process.env.REACT_APP_BACKUP === "true") &&(
        <li>
          <a className="ml-4 mr-4 btn btn-sm btn-info" href="https://portal.a2000.pt">
            DADOS ATUAIS
          </a>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button
            onClick={() => {
              auth.logout();
              history.push('/login');
            }}
          >
            SAIR
          </button>
        </li>
      )}
      
    </ul>
  );
};

export default NavLinks;
