import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

const SurveyTextarea = (props) => {
  const surveyCtx = useContext(SurveyContext);

  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value || '');
  };

  return (
    <div className="m-4 pt-2">
      <textarea
        placeholder={props.placeholder || 'Placeholder Text'}
        rows={props.rows || 5}
        className="form-control"
        id={props.id}
        name={props.id}
        onChange={onChangeHandler}
        value={surveyCtx.values[props.id] || ''}
      ></textarea>
    </div>
  );
};

export default SurveyTextarea;
