import React from 'react';
import HeaderDate from '../../../components/HeaderDate';
import HeaderText from '../../../components/HeaderText';

import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';
import SurveyTextarea from '../../../components/survey-textarea';
import MultiCheckbox from '../../../components/multi-checkbox';
import SubmitSurvey from '../../../components/SubmitSurvey';

import {
  A2000_145_QUESTIONS,
  A2000_145_ANSWERS,
  A2000_145_ANSWERS_Q2_1,
  A2000_145_ANSWERS_Q3_1,
  A2000_145_ANSWERS_Q3_2,
  A2000_145_ANSWERS_Q5,
} from './Questions_A2000_145';

import '../Survey.css';

const Survey_A2000_145 = (props) => {
  const { onChange, text } = props;
  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <h3 className="center text-white">Identificação do Cliente</h3>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderText name="name" placeholder="Nome" />
          <HeaderText
            name="habilitacoes"
            placeholder="Habilitações"
            style={{ width: '250px' }}
          />
          <HeaderText
            name="idade"
            placeholder="Idade"
            style={{ width: '75px' }}
          />
          <HeaderText
            name="acaoFormacao"
            placeholder="Ação de Formação Frequentada"
            style={{ width: '300px' }}
          />
          <HeaderText
            name="localFormacao"
            placeholder="Local em que frequentou a ação de formação"
            style={{ width: '370px' }}
          />

          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup name="Avaliação do Cliente">
        <SurveyQuestion
          answers={A2000_145_ANSWERS}
          questions={A2000_145_QUESTIONS}
          onChange={onChange}
          num="1"
        />
        <SurveyQuestion
          answers={A2000_145_ANSWERS}
          questions={A2000_145_QUESTIONS}
          onChange={onChange}
          num="2"
        />
        <div className="ml-4">
          <MultiCheckbox
            num="2_1"
            answers={A2000_145_ANSWERS_Q2_1}
            questions={A2000_145_QUESTIONS}
            onChange={onChange}
            showOthers="Outras..."
          />
        </div>
        <SurveyQuestion
          answers={A2000_145_ANSWERS}
          questions={A2000_145_QUESTIONS}
          onChange={onChange}
          num="3"
        />
        <div className="ml-4">
          <MultiCheckbox
            num="3_1"
            answers={A2000_145_ANSWERS_Q3_1}
            questions={A2000_145_QUESTIONS}
            onChange={onChange}
            showOthers="Outras..."
          />
          <MultiCheckbox
            num="3_2"
            answers={A2000_145_ANSWERS_Q3_2}
            questions={A2000_145_QUESTIONS}
            onChange={onChange}
            showOthers="Outras..."
          />
        </div>
        <SurveyQuestion
          answers={A2000_145_ANSWERS}
          questions={A2000_145_QUESTIONS}
          onChange={onChange}
          num="4"
        />
        <div className="ml-4">
          <MultiCheckbox
            num="4_1"
            answers={A2000_145_ANSWERS}
            questions={A2000_145_QUESTIONS}
            onChange={onChange}
          />
        </div>
        <SurveyQuestion
          answers={A2000_145_ANSWERS_Q5}
          questions={A2000_145_QUESTIONS}
          onChange={onChange}
          num="5"
        />
        <SurveyTextarea
          id="sugestoes"
          onChange={onChange}
          placeholder="Sugestões de melhoria para a A2000"
        />
      </SurveyGroup>
      <SurveyGroup>
        <div className="mt-4">
          <SubmitSurvey />
        </div>
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_145;
