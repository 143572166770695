export const A2000_145_QUESTIONS = {
  1: 'Após término da formação, conseguiu arranjar emprego?',
  2: 'Considera que os conhecimentos adquiridos têm aplicabilidade na sua profissão?',
  '2_1': 'Se respondeu não, porquê?',
  3: 'Considera que foi útil a formação que frequentou?',
  '3_1': 'Se respondeu sim, porquê?',
  '3_2': 'Se respondeu não, porquê?',
  4: 'Atualmente encontra-se empregado?',
  '4_1': 'Se respondeu não: Procurou emprego?  ',
  5: 'A formação frequentada foi ao encontro das suas expectativas?',
};

export const A2000_145_ANSWERS = [
  { value: '1', text: 'Sim' },
  { value: '4', text: 'Não' },
];

export const A2000_145_ANSWERS_Q2_1 = [
  { value: '1', text: 'Não foi valorizada no local de trabalho' },
  { value: '2', text: 'Os conteúdos eram desadequados à atividade exercida' },
  { value: '3', text: 'Carga horária insuficiente' },
];

export const A2000_145_ANSWERS_Q3_1 = [
  { value: '1', text: 'Integração no mercado de trabalho' },
  { value: '2', text: 'Aumento de conhecimentos profissionais' },
  { value: '3', text: 'Obtenção de recursos económicos' },
  {
    value: '4',
    text: 'Aumento da autonomia e capacidade de resolução de problemas',
  },
  { value: '5', text: 'Aumento da autoestima' },
  { value: '6', text: 'Aumento do grupo de amigos' },
  { value: '7', text: 'Melhor relacionamento familiar' },
  { value: '8', text: 'Aumento dos conhecimentos sobre direitos e deveres' },
  { value: '9', text: 'Acesso a novos serviços' },
  { value: '10', text: 'Ocupação de tempos livres' },
];

export const A2000_145_ANSWERS_Q3_2 = [
  { value: '1', text: 'Não proporcionou integração no mercado de trabalho' },
  { value: '2', text: 'Não proporcionou vantagem económica' },
  {
    value: '3',
    text: 'Não contribui para o meu desenvolvimento pessoal e social',
  },
  { value: '4', text: 'Não aumentou os conhecimentos profissionais' },
  { value: '5', text: 'Não aumento de cultura geral' },
];

export const A2000_145_ANSWERS_Q5 = [
  { value: '1', text: 'Nada' },
  { value: '2', text: 'Pouco' },
  { value: '3', text: 'Razoável' },
  { value: '4', text: 'Muito' },
];
