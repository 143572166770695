export const A2000_142_QUESTIONS = {
  1: 'O formador foi pontual e cumpriu os horários das sessões.',
  2: 'O formador apresentou os objetivos das unidades e as competências a adquirir.',
  3: 'O formador utilizou os métodos e as estratégias pedagógicas adequadas à diversidade do grupo.',
  4: 'O formador utilizou os recursos mais adequados a cada sessão para a transmissão dos conteúdos (computador, fichas de trabalho, textos informativos, quadro, filmes, etc.).',
  5: 'Os textos de apoio distribuídos pelo formador foram adequados, em quantidade e qualidade.',
  6: 'As propostas de trabalho (incluindo as saídas ao exterior) foram úteis para a aquisição dos conhecimentos /transmissão dos conteúdos.',
  7: 'O formador demonstrou dominar os conteúdos abordados.',
  8: 'O formador transmitiu com clareza os assuntos abordados.',
  9: 'O formador mostrou-se disponível para esclarecer as dúvidas apresentadas pelos formandos.',
  10: 'O formador promoveu o espírito de iniciativa e a autonomia dos formandos.',
  11: 'O formador conseguiu estimular/despertar o interesse dos formandos pelos conteúdos ministrados.',
  12: 'O formador estimulou a participação dos formandos nas sessões.',
  13: 'O formador manteve a disciplina e respeito na sala de formação.',
  14: 'O formador estabeleceu boa relação com todos os formandos, criando um clima de confiança.',
  15: 'O formador mostrou-se disponível para colaborar em diversas atividades (reuniões pedagógicas de conceção, de organização, reuniões de acompanhamento e avaliação, sugestões de melhoria…) dando sugestões/propostas de melhoria.',
  16: 'O formador cumpriu os prazos estabelecidos pela entidade (planificações, avaliações…) e realizou o preenchimento correto dos documentos do Dossier Técnico-Pedagógico.',
};

export const A2000_142_ANSWERS = [
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '0', text: 'Não se aplica / Não sei' },
];
