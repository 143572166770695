import React from 'react';
import { Button, Modal } from 'antd';

const EditServiceModal = (props) => {
  const {
    visible,
    handleSave,
    handleCancel,
    handleChange,
    service,
    update = false,
  } = props;
  return (
    <div>
      <Modal
        visible={visible}
        title={update ? 'Alterar serviço' : 'Novo serviço'}
        onOk={handleSave}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" onClick={handleSave} type="primary">
            Guardar
          </Button>,
        ]}
      >
        <input
          type="text"
          value={service.name}
          name="name"
          onChange={handleChange}
          className="form-control"
          placeholder="Nome"
        />
        <textarea
          className="mt-2 form-control"
          value={service.description}
          name="description"
          onChange={handleChange}
          placeholder="Descrição"
          rows="3"
        />
      </Modal>
    </div>
  );
};

export default EditServiceModal;
