
export const A2000_098_QUESTIONS = {
  "1": "A A2000 cumpre as suas responsabilidades de forma rigorosa, profissional e atempada?",
  "2": "Na relação estabelecida a A2000 incentivou-o/a à participação nas atividades/ projetos?",
  "3": "O serviço prestado pela A2000 é relevante na promoção dos direitos das pessoas mais vulneráveis, na comunidade?",
  "4": "Os resultados desta avaliação são divulgados às partes interessadas?",
  "5": "A A2000 correspondeu às minhas expetativas, a nível de:",
  "6": "Quais os serviços da A2000 que conhece?",
  "7": "Se a A2000 não existisse onde se notaria a diferença?",
  "8": "Qual o meio de divulgação da A2000 que conhece melhor?",
};

export const A2000_098_ANSWERS = [
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "0", text: "Não Sei" },
  { value: "-1", text: "Não se Aplica" },
]

export const A2000_098_ANSWERS_Q5 = [
  { value: "1", text: "Cumprimento da responsabilidade social" },
  { value: "2", text: "Promoção de uma maior inclusão " },
  { value: "3", text: "Prestação de um serviço solidário " },
]

export const A2000_098_ANSWERS_Q6 = [
  { value: "1", text: "Formação Profissional " },
  { value: "2", text: "Centro de Recursos para a Inclusão Profissional (CRIP)" },
  { value: "3", text: "Intervenção Precoce na Infância (IPI)" },
  { value: "4", text: "Espaços de Convívio (Gabinete Psicossocial - GPS)" },
  { value: "5", text: "Projetos apoiados pelo INR" },
  { value: "6", text: "Centro de Atendimento, Acompanhamento e Reabilitação Social para PCDI (CAARPD)" },
]

export const A2000_098_ANSWERS_Q7 = [
  { value: "1", text: "Em nenhuma área" },
  { value: "2", text: "Na Formação profissional" },
  { value: "3", text: "Na inclusão das pessoas com deficiência" },
  { value: "4", text: "Na dinamização de atividades sociais" },
  { value: "5", text: "Na dinamização de idosos autónomos" },
  { value: "6", text: "No apoio social a famílias" },
  { value: "7", text: "Na Intervenção precoce na infância" },
]

export const A2000_098_ANSWERS_Q8 = [
  { value: "1", text: "Site" },
  { value: "2", text: "Facebook" },
  { value: "3", text: "Cartazes/Folhetos" },
  { value: "4", text: "Newsletter" },
  { value: "5", text: "Clientes" },
]