import React from 'react';
import HeaderDate from '../../../components/HeaderDate';
import HeaderText from '../../../components/HeaderText';

import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';
import SurveyTextarea from '../../../components/survey-textarea';
import SubmitSurvey from '../../../components/SubmitSurvey';

import {
  A2000_099_QUESTIONS,
  A2000_099_ANSWERS,
  A2000_099_ANSWERS_2,
} from './Questions_A2000_099';

import '../Survey.css';

const Survey_A2000_099 = (props) => {
  const { onChange, text } = props;

  const groupA = [],
    groupB = [],
    groupC = [],
    groupD = [],
    groupE = [];

  //group A = Formação
  for (let i = 1; i <= 4; i++) {
    groupA.push(
      <SurveyQuestion
        answers={A2000_099_ANSWERS}
        questions={A2000_099_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }
  //group B = Condições de Trabalho
  for (let i = 5; i <= 12; i++) {
    groupB.push(
      <SurveyQuestion
        answers={A2000_099_ANSWERS}
        questions={A2000_099_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }
  //group C = Ambiente Relacional
  for (let i = 13; i <= 18; i++) {
    groupC.push(
      <SurveyQuestion
        answers={A2000_099_ANSWERS}
        questions={A2000_099_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }
  //group D = Realização Pessoal
  for (let i = 19; i <= 22; i++) {
    groupD.push(
      <SurveyQuestion
        answers={A2000_099_ANSWERS}
        questions={A2000_099_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }
  //group E = Trabalho e espaço de vida
  for (let i = 23; i <= 26; i++) {
    groupE.push(
      <SurveyQuestion
        answers={A2000_099_ANSWERS_2}
        questions={A2000_099_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderText name="name" placeholder="Nome do voluntário" />

          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup name="Formação">{groupA}</SurveyGroup>
      <SurveyGroup name="Condições de Trabalho">{groupB}</SurveyGroup>
      <SurveyGroup name="Ambiente Relacional">{groupC}</SurveyGroup>
      <SurveyGroup name="Realização Pessoal">{groupD}</SurveyGroup>
      <SurveyGroup name="Trabalho e espaço de vida">{groupE}</SurveyGroup>
      <SurveyGroup>
        <SurveyTextarea
          id="sugestoes"
          onChange={onChange}
          placeholder="Sugestões de melhoria para a A2000"
        />
        <SubmitSurvey />
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_099;
