import React from 'react';
import { HorizontalBar } from '@reactchartjs/react-chart.js';
// eslint-disable-next-line
//import ChartDataLabels from 'chartjs-plugin-datalabels';

const ChartByGroup = ({
  title,
  selectedGroups,
  selectedGroupsLabels,
  answerList,
  surveyStats,
  height = 150,
  style = {},
  reverseColors = false,
  largePalette = false,
}) => {
  const barColors = {
    4: 'rgb(52, 235, 186)',
    3: 'rgb(211, 235, 52)',
    2: 'rgb(235, 143, 52)',
    1: 'rgb(237, 31, 31)',
  };

  const barColorsReverse = {
    1: 'rgb(52, 235, 186)',
    2: 'rgb(211, 235, 52)',
    3: 'rgb(235, 143, 52)',
    4: 'rgb(237, 31, 31)',
  };

  const altPalette = {
    1: 'rgb(120, 75, 155)',
    2: 'rgb(210, 150, 140)',
    3: 'rgb(114, 148, 253)',
    4: 'rgb(112, 232, 154)',
    5: 'rgb(136, 163, 127)',
    6: 'rgb(174, 74, 52)',
    7: 'rgb(213, 225, 147)',
    8: 'rgb(47, 159, 100)',
    9: 'rgb(145, 159, 4)',
    10: 'rgb(128, 4, 205)',
  };

  const { groups } = surveyStats;

  const data = {
    labels: selectedGroupsLabels.map((label) => label),
    datasets: answerList
      .filter((q) => q.value > 0)
      .map((q) => {
        return {
          label: q.text,
          data: selectedGroups.map((name) => {
            if (!groups) return 0;
            if (!groups[name]) return 0;
            return (
              (groups[name][q.text] / groups[name].totals.validAnswers) *
              100
            ).toFixed(2);
          }),
          backgroundColor: (() => {
            if (largePalette) {
              return altPalette[q.value];
            } else {
              if (reverseColors) {
                return barColorsReverse[q.value];
              } else {
                return barColors[q.value];
              }
            }
          })(),
        };
      }),
  };

  const options = {
    title: {
      display: title,
      text: title,
      fontSize: 20,
    },
    legend: {
      labels: {
        fontSize: 14,
        fontColor: 'black',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 1,
            max: 100,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          if (value === '0.00') return '';
          else return value + '%';
        },
        color: '#000000',
        font: {
          size: 11,
        },
        anchor: 'end',
        align: function (value, context) {
          if (value.dataset.data[value.dataIndex] > 90) return 'start';
          else return 'end';
        },
      },
    },
  };

  return (
    <div style={style}>
      <HorizontalBar height={height} data={data} options={options} />
    </div>
  );
};

export default ChartByGroup;
