import React from 'react';

const SurveyGroup = (props) => {
  return (
    <div className="container mt-4">
      {props.name && (
        <div className="form-group row center m-0">
          <h3 className="survey-text">{props.name}</h3>
        </div>
      )}
      <div className="form-group d-block survey-card survey-bg m-0 pl-4">
        {props.children}
      </div>
    </div>
  );
};

export default SurveyGroup;
