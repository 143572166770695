import React, { useContext } from 'react';
import { SurveyContext } from '../../../../shared/context/survey-context';

const LinhaTabela = ({ name, text }) => {
  const surveyCtx = useContext(SurveyContext);
  return (
    <tr>
      <td className="align-middle">
        <h6>{text}</h6>
      </td>
      <td className="align-middle">
        <select
          className="form-control"
          value={surveyCtx.values.tabela1?.[name] || ''}
          name={name}
          onChange={(e) => {
            let value = { ...surveyCtx.values.tabela1 } || {};

            value[e.target.name] = e.target.value;

            surveyCtx.setValue('tabela1', value || {});
          }}
        >
          <option value="" disabled>
            Escolha
          </option>
          <option value="sim">Sim</option>
          <option value="nao">Não</option>
        </select>
      </td>
      <td className="align-middle">
        {surveyCtx.values.tabela1 && surveyCtx.values.tabela1[name] && (
          <textarea
            className="form-control"
            name={name + '_texto'}
            value={surveyCtx.values[name + '_texto'] || undefined}
            onChange={(e) =>
              surveyCtx.setValue(e.target.name, e.target.value || undefined)
            }
            rows="2"
          ></textarea>
        )}
      </td>
    </tr>
  );
};

export default LinhaTabela;
