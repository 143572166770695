import React, { useContext } from 'react';

import { SurveyContext } from '../../../../shared/context/survey-context';

const TabelaLivre = ({ titulo = '', linhasNomes = [] }) => {
  const surveyCtx = useContext(SurveyContext);
  return (
    <table className="table table-bordered mt-4">
      <thead className="thead-dark">
        <tr className="text-center">
          <th colSpan="3">{titulo.toUpperCase()}</th>
        </tr>
        <tr className="text-center">
          <th className="align-middle" scope="col" width="80%">
            Designação
          </th>
          <th className="align-middle" scope="col" width="15%">
            Nº Horas
          </th>
        </tr>
      </thead>
      <tbody>
        {linhasNomes.map((nome, index) => {
          return (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={surveyCtx.values[nome + '_DESC'] || ''}
                  onChange={(e) => {
                    let tmpValues = { ...surveyCtx.values };

                    if (!tmpValues.tabela2) tmpValues.tabela2 = {};

                    tmpValues[nome + '_DESC'] = e.target.value;
                    tmpValues.tabela2[nome] = e.target.value ? true : false;

                    surveyCtx.setValues(tmpValues);
                  }}
                />
              </td>
              <td colSpan="2" className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Horas"
                  value={surveyCtx.values[nome + '_HORAS'] || ''}
                  onChange={(e) =>
                    surveyCtx.setValue(nome + '_HORAS', e.target.value)
                  }
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TabelaLivre;
