import Survey_A2000_070 from './A2000_070/Survey_A2000_070';
import List_A2000_070 from './A2000_070/List_A2000_070';
import Stats_A2000_070 from './A2000_070/Stats_A2000_070';

import Survey_A2000_076 from './A2000_076/Survey_A2000_076';
import List_A2000_076 from './A2000_076/List_A2000_076';
import Stats_A2000_076 from './A2000_076/Stats_A2000_076';

import Survey_A2000_082 from './A2000_082/Survey_A2000_082';
import List_A2000_082 from './A2000_082/List_A2000_082';
import Stats_A2000_082 from './A2000_082/Stats_A2000_082';

import Survey_A2000_098 from './A2000_098/Survey_A2000_098';
import List_A2000_098 from './A2000_098/List_A2000_098';

import Survey_A2000_099 from './A2000_099/Survey_A2000_099';
import List_A2000_099 from './A2000_099/List_A2000_099';

import Survey_A2000_141 from './A2000_141/Survey_A2000_141';
import List_A2000_141 from './A2000_141/List_A2000_141';
import Stats_A2000_141 from './A2000_141/Stats_A2000_141';

import Survey_A2000_142 from './A2000_142/Survey_A2000_142';
import List_A2000_142 from './A2000_142/List_A2000_142';
import Stats_A2000_142 from './A2000_142/Stats_A2000_142';

import Survey_A2000_143 from './A2000_143/Survey_A2000_143';
import List_A2000_143 from './A2000_143/List_A2000_143';
import Stats_A2000_143 from './A2000_143/Stats_A2000_143';

import Survey_A2000_145 from './A2000_145/Survey_A2000_145';
import List_A2000_145 from './A2000_145/List_A2000_145';
import Stats_A2000_145 from './A2000_145/Stats_A2000_145';

import Survey_A2000_164 from './A2000_164/Survey_A2000_164';
import List_A2000_164 from './A2000_164/List_A2000_164';
import Stats_A2000_164 from './A2000_164/Stats_A2000_164';

import Survey_A2000_165 from './A2000_165/Survey_A2000_165';
import List_A2000_165 from './A2000_165/List_A2000_165';
import Stats_A2000_165 from './A2000_165/Stats_A2000_165';

const surveyMap = {
  A2000_070: {
    value: 'A2000_070',
    text: 'A2000.070 - Avaliação de Satisfação (Clientes)',
    survey: Survey_A2000_070,
    list: List_A2000_070,
    stats: Stats_A2000_070,
  },
  A2000_076: {
    value: 'A2000_076',
    text: 'A2000.076 - Avaliação de Satisfação (Parceiro/Financiador/Fornecedor)',
    survey: Survey_A2000_076,
    list: List_A2000_076,
    stats: Stats_A2000_076,
  },
  A2000_082: {
    value: 'A2000_082',
    text: 'A2000.082 - Avaliação de Satisfação (Colaboradores)',
    survey: Survey_A2000_082,
    list: List_A2000_082,
    stats: Stats_A2000_082,
  },
  A2000_098: {
    value: 'A2000_098',
    text: 'A2000.098 - Avaliação de Satisfação (Sócios)',
    survey: Survey_A2000_098,
    list: List_A2000_098,
  },
  A2000_099: {
    value: 'A2000_099',
    text: 'A2000.099 - Avaliação de Satisfação (Voluntários)',
    survey: Survey_A2000_099,
    list: List_A2000_099,
  },
  A2000_141: {
    value: 'A2000_141',
    text: 'A2000.141 - Avaliação de Desempenho do Formador (Formando)',
    survey: Survey_A2000_141,
    list: List_A2000_141,
    stats: Stats_A2000_141,
  },
  A2000_142: {
    value: 'A2000_142',
    text: 'A2000.142 - Avaliação de Desempenho do Formador (Coordenador)',
    survey: Survey_A2000_142,
    list: List_A2000_142,
    stats: Stats_A2000_142,
  },
  A2000_143: {
    value: 'A2000_143',
    text: 'A2000.143 - Auto-Avaliação do Formador',
    survey: Survey_A2000_143,
    list: List_A2000_143,
    stats: Stats_A2000_143,
  },
  A2000_145: {
    value: 'A2000_145',
    text: 'A2000.145 - Avaliação de Impacto da Formação',
    survey: Survey_A2000_145,
    list: List_A2000_145,
    stats: Stats_A2000_145,
  },
  A2000_164: {
    value: 'A2000_164',
    text: 'A2000.164 - Inquérito de Necessidades Sociais e Formativas',
    survey: Survey_A2000_164,
    list: List_A2000_164,
    stats: Stats_A2000_164,
  },
  A2000_165: {
    value: 'A2000_165',
    text: 'A2000.165 - Avaliação de Satisfação do Formador',
    survey: Survey_A2000_165,
    list: List_A2000_165,
    stats: Stats_A2000_165,
  },
};

export default surveyMap;
