import React, { useContext } from 'react';
import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';
import { SurveyContext } from '../../../../shared/context/survey-context';

import '../Survey.css';

import SurveyTextarea from '../../../components/survey-textarea';
import HeaderDate from '../../../components/HeaderDate';
import SubmitSurvey from '../../../components/SubmitSurvey';
import HeaderText from '../../../components/HeaderText';
import HeaderSelect from '../../../components/HeaderSelect';
import LinhaTabela from './LinhaTabela';

import {
  TABELA_NECESSIDADES_SOCIAIS,
  TABELA_NECESSIDADES_INFANCIA,
  TABELA_NECESSIDADES_GERIATRIA,
  TABELA_NECESSIDADES_ADMINISTRATIVA,
  TABELA_NECESSIDADES_AFAC,
  TABELA_NECESSIDADES_INFORMATICA,
  TABELA_NECESSIDADES_COMERCIO,
  TABELA_NECESSIDADES_TURISMO,
  TABELA_NECESSIDADES_HOTELARIA,
} from './consts';
import Tabela2 from './Tabela2';
import TabelaLivre from './TabelaLivre';

const Survey_A2000_164 = () => {
  const surveyCtx = useContext(SurveyContext);

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-90">
      <div className="container">
        <h2 className="center survey-text mb-5">
          A2000.164 - Inquérito de Necessidades Sociais e Formativas
        </h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderSelect
            options={[
              {
                value: 'entidade',
                text: 'Entidade',
              },
              {
                value: 'individual',
                text: 'Individual',
              },
            ]}
            name="respondepor"
            placeholder="Responde por:"
          />
          <SubmitSurvey inline />
        </div>
      </div>
      {surveyCtx.values['respondepor'] === 'entidade' && (
        <SurveyGroup name="DADOS DO INQUIRIDO">
          <div className="row">
            <HeaderText
              name="nome"
              placeholder="Nome"
              style={{ width: '350px' }}
            />
            <HeaderSelect
              options={[
                {
                  value: 'educacao',
                  text: 'Educação',
                },
                {
                  value: 'autarquia',
                  text: 'Autarquia (Câmara e Freguesias)',
                },
                {
                  value: 'agricultura',
                  text: 'Agricultura',
                },
                {
                  value: 'industria',
                  text: 'Indústria (Oficina mecânica; Alimentar; Armazéns etc.)',
                },
                {
                  value: 'construcao',
                  text: 'Construção',
                },
                {
                  value: 'comercio',
                  text: 'Comércio',
                },
                {
                  value: 'restauracao_hotelaria',
                  text: 'Restauração e Hotelaria',
                },
                {
                  value: 'ipss',
                  text: 'IPSS (Centros Sociais, Associações Fundações, Misericórdia)',
                },
                {
                  value: 'outra',
                  text: 'Outra...',
                },
              ]}
              name="ramo"
              placeholder="Ramo:"
              style={{ width: '300px' }}
            />
            {surveyCtx.values.ramo === 'outra' && (
              <HeaderText
                name="ramo_outra"
                placeholder="Ramo"
                style={{ width: '200px' }}
              />
            )}
            <HeaderText
              name="num_colaboradores"
              placeholder="Nº colaboradores"
              style={{ width: '150px' }}
            />
          </div>
          <div className="row">
            <HeaderText
              name="concelho"
              placeholder="Concelho"
              style={{ width: '236px' }}
            />
            <HeaderText
              name="freguesia"
              placeholder="Freguesia"
              style={{ width: '236px' }}
            />
          </div>
        </SurveyGroup>
      )}
      {surveyCtx.values['respondepor'] === 'individual' && (
        <SurveyGroup name="DADOS DO INQUIRIDO">
          <div className="row">
            <HeaderText
              name="idade"
              placeholder="Idade"
              style={{ width: '100px' }}
            />
            <HeaderSelect
              options={[
                {
                  value: 'masculino',
                  text: 'Masculino',
                },
                {
                  value: 'feminino',
                  text: 'Feminino',
                },
              ]}
              name="genero"
              placeholder="Género"
            />
            <HeaderSelect
              options={[
                {
                  value: 'desempregado',
                  text: 'Desempregado',
                },
                {
                  value: 'empregado',
                  text: 'Empregado',
                },
              ]}
              name="emprego"
              placeholder="Emprego"
            />
            {surveyCtx.values['emprego'] === 'empregado' && (
              <HeaderText
                name="profissao"
                placeholder="Profissão"
                style={{ width: '250px' }}
              />
            )}
            <HeaderSelect
              options={[
                {
                  value: 'analfabeto',
                  text: 'Analfabeto',
                },
                {
                  value: '1ciclo',
                  text: '1º Ciclo',
                },
                {
                  value: '2ciclo',
                  text: '2º Ciclo',
                },
                {
                  value: '3ciclo',
                  text: '3º Ciclo',
                },
                {
                  value: 'secundario',
                  text: 'Secundário',
                },
                {
                  value: 'superior',
                  text: 'Ensino Superior',
                },
              ]}
              name="escolaridade"
              placeholder="Escolaridade"
            />
          </div>
          <div className="row">
            <HeaderText
              name="concelho"
              placeholder="Concelho"
              style={{ width: '236px' }}
            />
            <HeaderText
              name="freguesia"
              placeholder="Freguesia"
              style={{ width: '236px' }}
            />
          </div>
        </SurveyGroup>
      )}
      <SurveyGroup>
        <SurveyQuestion
          answers={[
            { value: '1', text: 'Sim' },
            { value: '2', text: 'Não' },
          ]}
          questions={{ 1: 'Já ouviu falar da A2000?' }}
          num={1}
          key={1}
        />
        <SurveyQuestion
          answers={[
            { value: '1', text: 'Sim' },
            { value: '2', text: 'Não' },
          ]}
          questions={{ 2: 'Sabe o que faz a A2000?' }}
          num={2}
          key={2}
        />
        {surveyCtx.values['Q02'] === 'Sim' && (
          <SurveyTextarea
            id="Q02_exemplos"
            onChange={null}
            placeholder="Se sim, dê alguns exemplos:"
          />
        )}
        <SurveyTextarea
          id="Q03"
          onChange={null}
          placeholder="O que pensa da A2000?"
        />
      </SurveyGroup>

      <SurveyGroup name="2. NECESSIDADES SOCIAIS">
        <table className="table table-bordered mt-4">
          <thead className="thead-dark">
            <tr className="text-center">
              <th className="align-middle" scope="col">
                Serviços/Apoios
              </th>
              <th className="align-middle" scope="col">
                Existe
              </th>
              <th scope="col center">
                Refira por serviço/apoio:
                <br />O que faz falta? O que pode ser melhorado? E como?
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(TABELA_NECESSIDADES_SOCIAIS).map((entry, index) => {
              const [key, value] = entry;
              return <LinhaTabela key={index} name={key} text={value} />;
            })}
          </tbody>
        </table>
      </SurveyGroup>
      <SurveyGroup name="3. NECESSIDADES FORMATIVAS, POR ÁREA">
        <Tabela2 dadosTabela={TABELA_NECESSIDADES_INFANCIA} slug="INFANCIA" />
        <Tabela2 dadosTabela={TABELA_NECESSIDADES_GERIATRIA} slug="GERIATRIA" />
        <Tabela2
          dadosTabela={TABELA_NECESSIDADES_ADMINISTRATIVA}
          slug="ADMINISTRATIVA"
        />
        <Tabela2 dadosTabela={TABELA_NECESSIDADES_AFAC} slug="AFAC" />
        <Tabela2
          dadosTabela={TABELA_NECESSIDADES_INFORMATICA}
          slug="INFORMATICA"
        />
        <Tabela2 dadosTabela={TABELA_NECESSIDADES_COMERCIO} slug="COMERCIO" />
        <Tabela2 dadosTabela={TABELA_NECESSIDADES_TURISMO} slug="TURISMO" />
        <TabelaLivre
          titulo="LÍNGUAS ESTRANGEIRAS"
          linhasNomes={['LINGUA1', 'LINGUA2', 'LINGUA3']}
        />
        <Tabela2 dadosTabela={TABELA_NECESSIDADES_HOTELARIA} slug="HOTELARIA" />
        <TabelaLivre
          titulo="OUTRO TIPO DE NECESSIDADES FORMATIVAS"
          linhasNomes={['OUTRO1', 'OUTRO2', 'OUTRO3']}
        />
      </SurveyGroup>
      <SurveyGroup>
        <h5>Tem preferência por formação: </h5>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="presencial"
            checked={surveyCtx.values.presencial}
            onClick={(e) => surveyCtx.setValue('presencial', e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="presencial">
            Presencial
          </label>
        </div>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="blearning"
            checked={surveyCtx.values.blearning}
            onClick={(e) => surveyCtx.setValue('blearning', e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="blearning">
            B-Learning (Misto de presencial e E-Learning)
          </label>
        </div>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="elearning"
            checked={surveyCtx.values.elearning}
            onClick={(e) => surveyCtx.setValue('elearning', e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="elearning">
            E-Learning
          </label>
        </div>
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_164;
