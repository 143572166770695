import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';
import {
  A2000_145_ANSWERS,
  A2000_145_ANSWERS_Q2_1,
  A2000_145_ANSWERS_Q3_1,
  A2000_145_ANSWERS_Q3_2,
  A2000_145_ANSWERS_Q5,
  A2000_145_QUESTIONS,
} from './Questions_A2000_145';

import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import ChartByQuestion from '../charts/ChartByQuestion';

import QuestionTable from '../../../components/data/QuestionTable';
import TextCard from '../../../components/data/TextCard';
import TextCardSingleQuestion from '../../../components/data/TextCardSingleQuestion';
import ChartBySingleQuestion from '../charts/ChartBySingleQuestion';

const Stats_A2000_145 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [surveyStats, setSurveyStats] = useState({});
  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);

  const chartWidth = '100%';

  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_145/stats`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setSurveyStats(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [sendRequest, auth.token, selectedDates]);

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="center mt-2 mb-4">
          <RangePicker
            allowClear={false}
            defaultValue={selectedDates}
            locale={locale}
            onChange={handleDatesChange}
            style={{ marginRight: '10px' }}
          />
        </div>
        {surveyStats.totals && (
          <div className="center">
            <p className="text-white h4">
              Número de avaliações: {surveyStats.totals.totalSurveys}
            </p>
          </div>
        )}
        {surveyStats.totals && surveyStats.totals.totalSurveys > 0 && (
          <div>
            <TextCard
              title="Questões 1 a 4"
              answerList={A2000_145_ANSWERS}
              totals={surveyStats.totals}
              invalidAnswer="-"
            />
            <TextCardSingleQuestion
              title="Questão 2.1"
              answerList={A2000_145_ANSWERS_Q2_1}
              question={surveyStats.miscQuestions.Q2_1}
              invalidAnswer=""
              customTotal={surveyStats.totals.totalSurveys}
            />
            <TextCardSingleQuestion
              title="Questão 3.1"
              answerList={A2000_145_ANSWERS_Q3_1}
              question={surveyStats.miscQuestions.Q3_1}
              invalidAnswer=""
              customTotal={surveyStats.totals.totalSurveys}
            />
            <TextCardSingleQuestion
              title="Questão 3.2"
              answerList={A2000_145_ANSWERS_Q3_2}
              question={surveyStats.miscQuestions.Q3_2}
              invalidAnswer=""
              customTotal={surveyStats.totals.totalSurveys}
            />
            <TextCardSingleQuestion
              title="Questão 4.1"
              answerList={A2000_145_ANSWERS}
              question={surveyStats.miscQuestions.Q4_1}
              invalidAnswer=""
              customTotal={surveyStats.totals.totalSurveys}
            />
            <TextCardSingleQuestion
              title="Questão 5"
              answerList={A2000_145_ANSWERS_Q5}
              question={surveyStats.miscQuestions.Q05}
              invalidAnswer=""
            />
            <QuestionTable
              questionStart={1}
              questionEnd={4}
              excludeQuestions={[]}
              questionsList={surveyStats.questions}
              totals={surveyStats.totals}
              questionsText={A2000_145_QUESTIONS}
              answerList={A2000_145_ANSWERS}
              invalidAnswer="-"
              useTextOnColumnNames
              answerWidth="15%"
              questionWidth="70%"
            ></QuestionTable>
            <ChartByQuestion
              title="Questões 1 a 4"
              selectedQuestions={[1, 2, 3, 4]}
              answerList={A2000_145_ANSWERS}
              questionList={A2000_145_QUESTIONS}
              surveyStatsQuestions={surveyStats.questions}
              ignoreAnswerThreshold="-1"
              reverseColors
              height="100px"
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="2.1 - Se respondeu não, porquê?"
              selectedQuestions={['2_1']}
              answerList={A2000_145_ANSWERS_Q2_1}
              questionList={A2000_145_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              customTotal={surveyStats.totals.totalSurveys}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q2_1"
              largePalette
              height="75px"
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="3.1 - Se respondeu sim, porquê?"
              selectedQuestions={['3_1']}
              answerList={A2000_145_ANSWERS_Q3_1}
              questionList={A2000_145_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              customTotal={surveyStats.totals.totalSurveys}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q3_1"
              largePalette
              height="125px"
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="3.2 - Se respondeu não, porquê?"
              selectedQuestions={['3_2']}
              answerList={A2000_145_ANSWERS_Q3_2}
              questionList={A2000_145_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              customTotal={surveyStats.totals.totalSurveys}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q3_2"
              largePalette
              height="100px"
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
            <ChartBySingleQuestion
              title="4.1 - Se respondeu não: Procurou emprego?"
              selectedQuestions={['4_1']}
              answerList={A2000_145_ANSWERS}
              questionList={A2000_145_QUESTIONS}
              surveyStatsQuestions={surveyStats.miscQuestions}
              customTotal={surveyStats.totals.totalSurveys}
              ignoreAnswerThreshold="-1"
              singleQuestionName="Q4_1"
              largePalette
              height="50px"
              style={{
                width: chartWidth,
                margin: 'auto',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Stats_A2000_145;
