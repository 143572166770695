import React from 'react';

const QuestionTable = ({
  questionWidth = '50%',
  answerWidth = '10%',
  questionStart,
  questionEnd,
  excludeQuestions = [],
  questionsList,
  totals,
  questionsText,
  answerList,
  invalidAnswer,
  useTextOnColumnNames = false,
}) => {
  const generateTable = () => {
    let returnTable = [];
    for (let i = questionStart; i <= questionEnd; i++) {
      if (excludeQuestions.includes(i)) continue;

      const tmpQuestionName = 'Q' + i.toString().padStart(2, '0');

      if (!questionsList) {
        return null;
      }

      returnTable.push(
        <tr key={i}>
          <td style={{ width: questionWidth, textAlign: 'left' }}>
            <strong>{i + '. '}</strong>
            {questionsText[i]}
          </td>
          {answerList.map((ans) => {
            if (ans.text === invalidAnswer) {
              return (
                <td key={ans.value}>
                  {((questionsList[tmpQuestionName] &&
                    '(' + questionsList[tmpQuestionName][ans.text]) ||
                    0) + ')'}
                </td>
              );
            } else {
              return (
                <td key={ans.value}>
                  {questionsList[tmpQuestionName] &&
                    (
                      (questionsList[tmpQuestionName][ans.text] /
                        questionsList[tmpQuestionName].totals.validAnswers ||
                        0) * 100
                    ).toFixed(2) +
                      '% (' +
                      questionsList[tmpQuestionName][ans.text] +
                      ')'}
                </td>
              );
            }
          })}
        </tr>
      );

      returnTable.push();
    }

    return returnTable;
  };

  return (
    <div>
      {
        //renderizar a legenda apenas se useTextOnColumnNames for falso
        !useTextOnColumnNames && (
          <div className="center mt-5 print">
            <p className="text-white h6">
              {answerList.map((ans) => {
                return (
                  <span className="text-white h6 mr-4" key={ans.value}>
                    <strong>{ans.value}:</strong> {ans.text}
                  </span>
                );
              })}
            </p>
          </div>
        )
      }
      <div className="center">
        <table className="table table-striped bg-light">
          <thead>
            <tr>
              <th
                scope="col"
                style={{ width: questionWidth, textAlign: 'left' }}
              >
                Pergunta
              </th>
              {answerList.length &&
                answerList.map((ans) => (
                  <th
                    scope="col"
                    key={ans.value}
                    style={{ width: answerWidth }}
                  >
                    {useTextOnColumnNames ? ans.text : ans.value}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>{generateTable()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default QuestionTable;
