import React from 'react';
import { HorizontalBar } from '@reactchartjs/react-chart.js';
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';

const ChartDesempenhoFormador = ({
  desempenhoArray,
  reverseColors = false,
  largePalette = false,
  height = 450,
  style = {},
  ignoreAnswerThreshold = 0,
}) => {
  const barColors = {
    4: 'rgb(52, 235, 186)',
    3: 'rgb(211, 235, 52)',
    2: 'rgb(235, 143, 52)',
    1: 'rgb(237, 31, 31)',
  };

  const barColorsReverse = {
    1: 'rgb(52, 235, 186)',
    2: 'rgb(211, 235, 52)',
    3: 'rgb(235, 143, 52)',
    4: 'rgb(237, 31, 31)',
  };

  const altPalette = {
    1: 'rgb(120, 75, 155)',
    2: 'rgb(210, 150, 140)',
    3: 'rgb(114, 148, 253)',
    4: 'rgb(112, 232, 154)',
    5: 'rgb(136, 163, 127)',
    6: 'rgb(174, 74, 52)',
    7: 'rgb(213, 225, 147)',
    8: 'rgb(47, 159, 100)',
    9: 'rgb(145, 159, 4)',
    10: 'rgb(128, 4, 205)',
  };

  var yourSplit = function (N, string) {
    var app = string.split(' '),
      arrayApp = [],
      stringApp = '';
    app.forEach(function (sentence, index) {
      stringApp += sentence + ' ';

      if ((index + 1) % N === 0) {
        arrayApp.push(stringApp);
        stringApp = '';
      } else if (app.length === index + 1 && stringApp !== '') {
        arrayApp.push(stringApp);
        stringApp = '';
      }
    });
    return arrayApp;
  };

  if (!desempenhoArray.listagem) return <div></div>;

  const data = {
    labels: desempenhoArray.listagem.map((questionNum) => {
      return yourSplit(8, questionNum.texto);
    }),
    datasets: [
      {
        label: 'Formando',
        data: desempenhoArray.listagem.map((e) => e.formando),
        backgroundColor: 'rgb(237, 31, 31)',
      },
      {
        label: 'Coordenador',
        data: desempenhoArray.listagem.map((e) => e.coordenador),
        backgroundColor: 'rgb(235, 143, 52)',
      },
      {
        label: 'Formador',
        data: desempenhoArray.listagem.map((e) => e.formador),
        backgroundColor: 'rgb(211, 235, 52)',
      },
    ],
  };

  const options = {
    title: {
      display: 'Desempenho Formador',
      text: 'Desempenho Formador',
      fontSize: 20,
    },
    legend: {
      labels: {
        fontSize: 14,
        fontColor: 'black',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 0,
            max: 5,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        color: '#000000',
        font: {
          size: 11,
        },
        anchor: 'end',
        align: function (value, context) {
          if (value.dataset.data[value.dataIndex] > 4) return 'start';
          else return 'end';
        },
      },
    },
  };

  return (
    <div style={style}>
      <HorizontalBar height={height} data={data} options={options} />
    </div>
  );
};

export default ChartDesempenhoFormador;
