export const A2000_076_QUESTIONS = {
  1: 'Os compromissos/objetivos definidos entre a A2000 e a sua entidade estão claramente estabelecidos?',
  2: 'Os compromissos assumidos pela A2000 são respeitados e implementados, por esta?',
  3: 'A A2000 cumpre as suas responsabilidades de forma rigorosa, profissional e atempada?',
  4: 'A frequência dos contactos estabelecidos é a mais adequada à prestação dos serviços?',
  5: 'Na relação estabelecida a A2000 incentivou à inovação e à participação noutras atividades/ projetos/acordos?',
  6: 'Os resultados da avaliação desta relação interinstitucional são divulgados às partes interessadas?',
  7: 'O serviço prestado pela A2000 é relevante na promoção dos direitos das pessoas mais vulneráveis, na comunidade?',
  8: 'A relação institucional correspondeu às expetativas da minha entidade, a nível de:',
  9: 'Quais os serviços da A2000 que conhece?',
  10: 'Se a A2000 não existisse onde se notaria a diferença?',
  11: 'Qual o meio de divulgação da A2000 que conhece melhor?',
};

export const A2000_076_ANSWERS = [
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '0', text: 'Não Sei' },
  { value: '-1', text: 'Não se Aplica' },
];

export const A2000_076_ANSWERS_HEADER_1 = [
  { value: '1', text: 'Parceiro' },
  { value: '2', text: 'Financiador' },
  { value: '3', text: 'Fornecedor' },
];

export const A2000_076_ANSWERS_HEADER_2 = [
  { value: '1', text: 'Formação em Contexto de Trabalho' },
  { value: '2', text: 'IPI' },
  { value: '3', text: 'Espaços de Convívio' },
  { value: '4', text: 'CAARPD' },
  { value: '5', text: 'Logística' },
  { value: '6', text: 'Área da Recreação' },
  { value: '7', text: 'Área da Saúde' },
  { value: '8', text: 'CRIP' },
  { value: '9', text: 'Mecenato' },
  { value: '10', text: 'INR' },
  { value: '11', text: 'CLDS' },
];

export const A2000_076_ANSWERS_Q8 = [
  { value: '1', text: 'Aumento da notoriedade ' },
  { value: '2', text: 'Promoção de uma maior inclusão' },
  { value: '3', text: 'Prestação de um serviço solidário' },
  { value: '4', text: 'Aumento dos ganhos' },
  { value: '5', text: 'Melhoria das relações interpessoais' },
  { value: '6', text: 'Não sei/ Não responde' },
];

export const A2000_076_ANSWERS_Q9 = [
  { value: '1', text: 'Formação Profissional' },
  {
    value: '2',
    text: 'Centro de Recursos para a Inclusão Profissional (CRIP)',
  },
  { value: '3', text: 'Intervenção Precoce na Infância (IPI)' },
  { value: '4', text: 'Espaços de Convívio (Gabinete Psicossocial - GPS)' },
  { value: '5', text: 'Projetos Apoiados pelo INR' },
  {
    value: '6',
    text:
      'Centro de Atendimento, Acompanhamento e Reabilitação Social para PCDI (CAARPD)',
  },
];

export const A2000_076_ANSWERS_Q10 = [
  { value: '1', text: 'Em nenhuma área' },
  { value: '2', text: 'Na Formação profissional' },
  { value: '3', text: 'Na inclusão das pessoas com deficiência' },
  { value: '4', text: 'Na dinamização de atividades sociais' },
  { value: '5', text: 'Na dinamização de idosos autónomos' },
  { value: '6', text: 'No apoio social a famílias' },
  { value: '7', text: 'Na Intervenção precoce na infância' },
];

export const A2000_076_ANSWERS_Q11 = [
  { value: '1', text: 'Site' },
  { value: '2', text: 'Facebook' },
  { value: '3', text: 'Cartazes/Folhetos' },
  { value: '4', text: 'Newsletter' },
  { value: '5', text: 'Clientes' },
];
