import React, { useEffect, useState, useContext } from 'react';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { SurveyContext } from '../../../../shared/context/survey-context';

import DataGrid from '../../../components/data/DataGrid';
import { gridConfig } from '../common/config';

const List_A2000_098 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [serviceListToggle, setServiceListToggle] = useState(false);
  const surveyCtx = useContext(SurveyContext);

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/${surveyCtx.selectedSurvey}`
        );

        setRowData(() => {
          return responseData.data.map((survey) => {
            return {
              survey: survey,
              date: new Date(survey.date).toLocaleDateString(),
              createdby: survey.created.createdBy.name,
              name: survey.name,
            };
          });
        });
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, serviceListToggle, surveyCtx.selectedSurvey]);

  return (
    <DataGrid
      rows={rowData}
      columns={[
        {
          ...gridConfig.defaultColumn,
          headerName: 'Data',
          field: 'date',
          width: gridConfig.colWidth.date,
        },
        {
          ...gridConfig.defaultColumn,
          headerName: 'Criado por',
          field: 'createdby',
          width: gridConfig.colWidth.createdby,
        },

        {
          ...gridConfig.defaultColumn,
          headerName: 'Nome',
          field: 'name',
          width: gridConfig.colWidth.name,
        },
      ]}
      render={() => setServiceListToggle(!serviceListToggle)}
    />
  );
};

export default List_A2000_098;
