
import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';


import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import ChartByQuestion from '../charts/ChartByQuestion';
import ChartByGroup from '../charts/ChartByGroup';
import QuestionTable from '../../../components/data/QuestionTable';
import TextCard from '../../../components/data/TextCard';
import SelectMultipleServices from '../../../components/SelectMultipleServices';
import SelectMultipleLocations from '../../../components/SelectMultipleLocations';
import SelectFormadorNew from '../../../components/SelectFormadorNew';
import Chart164_1 from './Chart164_1';


const Stats_A2000_164 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [surveyStats, setSurveyStats] = useState({});
  
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_164/stats`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setSurveyStats(responseData.data);
        console.log(responseData.data.percent2)
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [
    sendRequest,
    auth.token,
    
  ]);



  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="center mt-2 mb-4">
         
          <Chart164_1 values={surveyStats.percent2} />
        </div>
        
      </div>
    </div>
  );
};

export default Stats_A2000_164;
