import React, { useContext, useState, useEffect } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const HeaderService = ({ placeholder = 'Serviço', todos = false }) => {
  const surveyCtx = useContext(SurveyContext);

  const [loadedServices, setLoadedServices] = useState([]);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchUserPlaces = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/services?select=name&sort=name`
        );

        setLoadedServices(responseData.data);
      } catch (err) {}
    };
    fetchUserPlaces();
  }, [sendRequest]);

  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value || undefined);
  };
  return (
    <div className="col-xs-2 form-inline m-2">
      <select
        onChange={onChangeHandler}
        className="form-control"
        id="service"
        name="service"
        value={
          surveyCtx.values['service'] ? surveyCtx.values['service']._id : ''
        }
        style={{ width: 'auto', maxWidth: '220px' }}
      >
        {!todos ? (
          <option value="" disabled>
            {placeholder}
          </option>
        ) : (
          <option value="*">Todos</option>
        )}

        {loadedServices.map((serv) => (
          <option key={serv._id} value={serv._id}>
            {serv.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HeaderService;
