
export const A2000_099_QUESTIONS = {
  "1": "Nº das ações realizadas",
  "2": "Qualidade das ações / formadores",
  "3": "Interesse dos assuntos abordados",
  "4": "Resultados obtidos",
  "5": "Instalações/Posto de trabalho",
  "6": "Equipamentos/Ferramentas",
  "7": "Autonomia",
  "8": "Metodologias de trabalho/Equipa",
  "9": "Tarefas/funções que desempenha",
  "10": "Informação a que tem acesso para a realização do seu trabalho ",
  "11": "Criatividade/iniciativa permitidas pela organização aos voluntários",
  "12": "Volume de trabalho",
  "13": "Liberdade de expressão da sua opinião aos dirigentes da A2000",
  "14": "Integração no local onde presta voluntariado",
  "15": "Cooperação entre si e a Equipa da A2000",
  "16": "Avaliação do seu trabalho pela Equipa da A2000",
  "17": "Relacionamento humano e profissional com clientes",
  "18": "Satisfação com o público-alvo com que trabalha",
  "19": "Motivação individual",
  "20": "Reconhecimento adequado e justo",
  "21": "Aprendizagem, conhecimento e desempenho individual",
  "22": "Autoconfiança no desempenho da função que ocupa",
  "23": "Obtém satisfação pessoal com o voluntariado?",
  "24": "O voluntariado Influencia positivamente a sua vida familiar?",
  "25": "Tem tempo disponível para outras atividades de lazer/ sociais?",
  "26": "Sente orgulho em dizer onde presta voluntariado?",
};

export const A2000_099_ANSWERS = [
  { value: "4", text: "Satisfaz Bastante" },
  { value: "3", text: "Satisfaz" },
  { value: "2", text: "Satisfaz Pouco" },
  { value: "1", text: "Não Satisfaz" },
]

export const A2000_099_ANSWERS_2 = [
  { value: "4", text: "Sempre" },
  { value: "3", text: "Moderadamente" },
  { value: "2", text: "Raramente" },
  { value: "1", text: "Nunca" }
]
