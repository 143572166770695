import React from 'react';
import { HorizontalBar, Pie } from '@reactchartjs/react-chart.js';
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Chart164_1 = ({
  title = "Interesse por Área",
  values,
  height = 450,
  style = {},
  ignoreAnswerThreshold = 0,
  singleQuestionName = null,
  barColor = 'rgb(112, 177, 255)',
}) => {
  

  if(!values) return <div></div>;

  const data = {
    labels: [
      "INFÂNCIA", 
      "GERIATRIA", 
      "ADMINISTRATIVA", 
      "AFAC", 
      "INFORMÁTICA", 
      "COMÉRCIO", 
      "TURISMO", 
      "LÍNGUAS ESTRANGEIRAS",
      "HOTELARIA",
      "OUTROS" ],
    datasets: [
      {
        label: 'Dataset 1',
        data: [
          (values.infancia*100).toFixed(2),
          (values.geriatria*100).toFixed(2),
          (values.administrativa*100).toFixed(2),
          (values.afac*100).toFixed(2),
          (values.informatica*100).toFixed(2),
          (values.comercio*100).toFixed(2),
          (values.turismo*100).toFixed(2),
          (values.lingua*100).toFixed(2),
          (values.hotelaria*100).toFixed(2),
          (values.outro*100).toFixed(2)
        ],
        //borderColor: 'rgb(112, 177, 255)',
        backgroundColor: ['#5c2945', '#7c314d', '#9c3a50', '#ba474e', '#d45847', '#e96e3a', '#f88928', '#ffa600', '#e89b23', '#bb853f', ],
      },
      
    ]
  };

  function GetMaxValue() {
    let maxValue = 0;
    Object.entries(values).map(val => {
      if(val[1] > maxValue) maxValue = val[1];
    })

    if(maxValue > 0.90) return 100;

    return ((maxValue * 100)+10).toFixed(0);
  }

  console.log(GetMaxValue());

  const options = {
    title: {
      display: title,
      text: title,
      fontSize: 20,
    },
    legend: {
      display: false,
      labels: {
        fontSize: 14,
        fontColor: 'black',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 1,
            max: Number.parseInt(GetMaxValue()),
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          if (value === '0.00') return '';
          else return value + '%';
        },
        color: '#000000',
        font: {
          size: 11,
        },
        anchor: 'end',
        align: function (value, context) {
          if (value.dataset.data[value.dataIndex] > 90) return 'start';
          else return 'end';
        },
      },
    },
  };

  const optionsPie = {
    title: {
      display: title,
      text: title,
      fontSize: 20,
      padding: 30
    },
    legend: {
      display: false,
      labels: {
        fontSize: 11,
        fontColor: 'black',
      },
    },
    layout: {
      padding: {
        bottom: 50
      }
    },
    
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          if (value === '0.00') return '';
          else return context.chart.data.labels[context.dataIndex] + " (" + value + '%)';
        },
        color: 'black',
        font: {
          size: 12,
        },
        anchor: 'end',
        align: function (value, context) {
          if (value.dataset.data[value.dataIndex] > 90) return 'start';
          else return 'end';
        },
      },
    },
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className="col bg-white p-1 m-2" style={style}>
          <Pie className="p-4" width="750" height={height} data={data} options={optionsPie} />
        </div>
      </div>
      <div className='row'>
        <div className="col bg-white p-1 m-2" style={style}>
          <HorizontalBar className="p-4" width="750" height={height} data={data} options={options} />
        </div>
      </div>      
    </div>
    
  );
};

export default Chart164_1;
