import React from 'react';
import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';

import '../Survey.css';
import { A2000_141_QUESTIONS, A2000_141_ANSWERS } from './Questions_A2000_141';
import SurveyTextarea from '../../../components/survey-textarea';
import HeaderDate from '../../../components/HeaderDate';
import HeaderService from '../../../components/HeaderService';
import HeaderText from '../../../components/HeaderText';
import SubmitSurvey from '../../../components/SubmitSurvey';
import SelectFormador from '../../../components/SelectFormador';

const Survey_A2000_141 = ({ onChange, text }) => {
  const generateQuestions = (start, end) => {
    const retArray = [];

    for (let i = start; i <= end; i++) {
      retArray.push(
        <SurveyQuestion
          answers={A2000_141_ANSWERS}
          questions={A2000_141_QUESTIONS}
          onChange={onChange}
          num={i}
          key={i}
        />
      );
    }
    return retArray;
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <SelectFormador />
          <HeaderService placeholder="Ação" />
          <HeaderText name="name" placeholder="Nome" />
          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup name="Avaliação de Desempenho do Formador">
        {generateQuestions(1, 15)}
        <SurveyTextarea
          id="sugestoes"
          onChange={onChange}
          placeholder="Nota: quando avaliar negativamente algum critério (1 ou 2) justificar ou indicar melhorias possíveis, por favor"
        />
      </SurveyGroup>
      <SurveyGroup>
        <div className="mt-4">
          <SubmitSurvey />
        </div>
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_141;
