import React, { useState, useContext, useEffect } from 'react';
import SelectSurvey from '../components/select-survey';
import { Modal } from 'antd';
import { SurveyContext } from '../../shared/context/survey-context';

import surveyMap from './surveys/surveyMap';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const Surveys = ({ selSurvey }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [selectedSurvey, setSelectedSurvey] = useState(selSurvey || '');
  const [values, setValues] = useState({});
  const [generatedView, setGeneratedView] = useState(null);
  const auth = useContext(AuthContext);

  const surveyChangeHandler = (e) => {
    setSelectedSurvey(e.target.value);
    changeView('');
    setValues({});
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (selectedSurvey) {
      //console.log('SELSURVEY: ', selectedSurvey);
      const SurveyComponent = surveyMap[selectedSurvey].survey;
      setGeneratedView(
        <SurveyComponent
          submitDisabled={false}
          values={{}}
          text={surveyMap[selectedSurvey].text}
        />
      );
    }
  }, [selectedSurvey]);

  const submitSurvey = async () => {
    if (values._id) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/${selectedSurvey}/${values._id}`,
          'PUT',
          JSON.stringify(values),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        if (!responseData.error) {
          Modal.success({
            title: 'Mensagem',
            content: responseData.message,
          });
          setValues({});
          changeView('list');
        }
      } catch (e) {
        console.log(e);
      }

      return;
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/surveys/' + selectedSurvey,
        'POST',
        JSON.stringify(values),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (!responseData.error) {
        Modal.success({
          title: 'Mensagem',
          content: responseData.message,
        });
        setValues({});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleButtons = (e) => {
    if (!selectedSurvey) return;
    else {
      if (e.target.name === 'new') {
        setValues({});
      }
      changeView(e.target.name);
    }
  };

  const changeView = (viewName) => {
    let SurveyComponent = null;
    switch (viewName) {
      case 'new':
        SurveyComponent = surveyMap[selectedSurvey].survey;
        if (SurveyComponent) {
          setGeneratedView(
            <SurveyComponent
              submitDisabled={false}
              values={values}
              text={surveyMap[selectedSurvey].text}
            />
          );
        }
        break;
      case 'list':
        SurveyComponent = surveyMap[selectedSurvey].list;
        if (SurveyComponent) {
          setGeneratedView(
            <SurveyComponent
              submitDisabled={false}
              values={values}
              text={surveyMap[selectedSurvey].text}
            />
          );
        }
        break;
      case 'stats':
        SurveyComponent = surveyMap[selectedSurvey].stats;
        if (SurveyComponent) {
          setGeneratedView(
            <SurveyComponent
              submitDisabled={false}
              values={values}
              text={surveyMap[selectedSurvey].text}
            />
          );
        }
        break;
      default:
        setGeneratedView(<div></div>);
    }
  };

  return (
    <SurveyContext.Provider
      value={{
        values,
        setValue: (fieldName, value) => {
          setValues({ ...values, [fieldName]: value });
        },
        setValues,
        submitSurvey,
        selectedSurvey,
        changeView,
      }}
    >
      {!selSurvey && (
        <SelectSurvey
          value={selectedSurvey}
          onChange={surveyChangeHandler}
          options={surveyMap}
          handleButtons={handleButtons}
        />
      )}
      {generatedView}
    </SurveyContext.Provider>
  );
};

export default Surveys;
