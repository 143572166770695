import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

import './multi-checkbox.css';
import QuestionHeader from './question-header';

const MultiCheckbox = (props) => {
  const { num, questions, answers, showOthers, customHeader } = props;
  const questionName = num ? 'Q' + num.toString().padStart(2, '0') : 'default';

  const surveyCtx = useContext(SurveyContext);

  const onChangeHandler = (e) => {
    let tmpSelected = surveyCtx.values[questionName] || [];

    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        tmpSelected.push(e.target.value);
      } else {
        const index = tmpSelected.indexOf(e.target.value);
        if (index > -1) tmpSelected.splice(index, 1);
      }

      surveyCtx.setValue(questionName, tmpSelected);
    }

    if (e.target.type === 'text') {
      surveyCtx.setValue(e.target.name, e.target.value || '');
    }
  };

  return (
    <div className="m-4">
      {questions ? (
        <QuestionHeader questionNum={num}>{questions[num]}</QuestionHeader>
      ) : (
        <QuestionHeader>{customHeader}</QuestionHeader>
      )}
      <div className="form-check mt-2 ml-4">
        {answers.map((ans) => {
          return (
            <div className="mt-2" key={questionName + '_' + ans.value}>
              <input
                onChange={onChangeHandler}
                className="form-check-input survey-checkbox"
                type="checkbox"
                id={questionName + '_' + ans.value}
                name={questionName + '_' + ans.value}
                value={ans.text}
                checked={
                  surveyCtx.values[questionName]
                    ? surveyCtx.values[questionName].includes(ans.text)
                    : false
                }
              />
              <label
                className="form-check-label survey-label"
                htmlFor={questionName + '_' + ans.value}
              >
                {ans.text}
              </label>
            </div>
          );
        })}
      </div>
      {showOthers && (
        <div className="form-check mt-3 ml-1">
          <input
            onChange={onChangeHandler}
            className="form-control"
            type="text"
            id={questionName + '_others'}
            name={questionName + '_others'}
            value={surveyCtx.values[questionName + '_others'] || ''}
            placeholder={showOthers}
            style={{ maxWidth: '400px' }}
          />
        </div>
      )}
    </div>
  );
};

export default MultiCheckbox;
