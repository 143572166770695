import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';
import { A2000_070_ANSWERS, A2000_070_QUESTIONS } from './Questions_A2000_070';
import SelectMultipleServices from '../../../components/SelectMultipleServices';
//import 'moment/locale/pt-pt';
import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import ChartByQuestion from '../charts/ChartByQuestion';
import ChartByGroup from '../charts/ChartByGroup';

const Stats_A2000_070 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [surveyStats, setSurveyStats] = useState({});
  const [selectedServices, setSelectedServices] = useState({});
  const [selectedSubject, setSelectedSubject] = useState(undefined);
  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);

  const chartWidth = '73%';

  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  function handleChange(value) {
    setSelectedServices(value);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_070/stats`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
            service: selectedServices,
            subject: selectedSubject,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setSurveyStats(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [
    sendRequest,
    auth.token,
    selectedServices,
    selectedDates,
    selectedSubject,
  ]);

  const generateTable = () => {
    let returnTable = [];

    const tmpQuestions = surveyStats.questions;

    let questionNumber = 0;

    for (let q in tmpQuestions) {
      questionNumber++;
      returnTable.push(
        <tr key={questionNumber}>
          <td>
            <strong>{questionNumber + '. '}</strong>
            {A2000_070_QUESTIONS[questionNumber]}
          </td>
          <td>
            {tmpQuestions[q].totals.validAnswers
              ? (
                  (tmpQuestions[q]['Nada Satisfeito'] /
                    tmpQuestions[q].totals.validAnswers) *
                  100
                ).toFixed(2) +
                '% (' +
                tmpQuestions[q]['Nada Satisfeito'] +
                ')'
              : '0.00% (0)'}
          </td>
          <td>
            {tmpQuestions[q].totals.validAnswers
              ? (
                  (tmpQuestions[q]['Pouco Satisfeito'] /
                    tmpQuestions[q].totals.validAnswers) *
                  100
                ).toFixed(2) +
                '% (' +
                tmpQuestions[q]['Pouco Satisfeito'] +
                ')'
              : '0.00% (0)'}
          </td>
          <td>
            {tmpQuestions[q].totals.validAnswers
              ? (
                  (tmpQuestions[q]['Satisfeito'] /
                    tmpQuestions[q].totals.validAnswers) *
                  100
                ).toFixed(2) +
                '% (' +
                tmpQuestions[q]['Satisfeito'] +
                ')'
              : '0.00% (0)'}
          </td>
          <td>
            {tmpQuestions[q].totals.validAnswers
              ? (
                  (tmpQuestions[q]['Muito Satisfeito'] /
                    tmpQuestions[q].totals.validAnswers) *
                  100
                ).toFixed(2) +
                '% (' +
                tmpQuestions[q]['Muito Satisfeito'] +
                ')'
              : '0.00% (0)'}
          </td>
          <td>{'(' + tmpQuestions[q]['Não se aplica / Não sei'] + ')'}</td>
        </tr>
      );
    }
    return returnTable;
  };

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  const handleSubjectChanges = (value) => {
    setSelectedSubject(value);
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="center mt-2 mb-4">
          <RangePicker
            allowClear={false}
            defaultValue={selectedDates}
            locale={locale}
            onChange={handleDatesChange}
            style={{ marginRight: '10px' }}
          />
          <SelectMultipleServices onChange={handleChange} />
          <Select
            placeholder="Cliente/Significativo"
            allowClear
            onChange={handleSubjectChanges}
            style={{ width: '300px', marginLeft: '10px' }}
          >
            <Option value={'cliente'}>Cliente</Option>
            <Option value={'significativo'}>Significativo</Option>
          </Select>
        </div>

        {surveyStats.totals && (
          <div>
            <div className="center">
              <p className="text-white h6">
                Número de avaliações: {surveyStats.totals.totalSurveys}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Muito Satisfeito:{' '}
                {(
                  (surveyStats.totals['Muito Satisfeito'] /
                    surveyStats.totals.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals['Muito Satisfeito'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Satisfeito:{' '}
                {(
                  (surveyStats.totals['Satisfeito'] /
                    surveyStats.totals.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals['Satisfeito'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Pouco Satisfeito:{' '}
                {(
                  (surveyStats.totals['Pouco Satisfeito'] /
                    surveyStats.totals.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals['Pouco Satisfeito'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Nada Satisfeito:{' '}
                {(
                  (surveyStats.totals['Nada Satisfeito'] /
                    surveyStats.totals.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals['Nada Satisfeito'] || 0) + ')'}
              </p>
            </div>
          </div>
        )}

        <div className="mt-5 center">
          <span className="text-white h6">
            <strong>1:</strong> Nada Satisfeito
          </span>
          <span className="ml-4 text-white h6">
            <strong>2:</strong> Pouco Satisfeito
          </span>
          <span className="ml-4 text-white h6">
            <strong>3:</strong> Satisfeito
          </span>
          <span className="ml-4 text-white h6">
            <strong>4:</strong> Muito Satisfeito
          </span>
          <span className="ml-4 text-white h6">
            <strong>5:</strong> Não se aplica / Não sei
          </span>
        </div>
        <table className="mt-2 table table-striped bg-light">
          <thead>
            <tr>
              <th scope="col" style={{ width: '50%' }}>
                Pergunta
              </th>
              <th scope="col" style={{ width: '10%' }}>
                1
              </th>
              <th scope="col" style={{ width: '10%' }}>
                2
              </th>
              <th scope="col" style={{ width: '10%' }}>
                3
              </th>
              <th scope="col" style={{ width: '10%' }}>
                4
              </th>
              <th scope="col" style={{ width: '10%' }}>
                5
              </th>
            </tr>
          </thead>
          <tbody>{generateTable()}</tbody>
        </table>
      </div>

      <ChartByGroup
        title="Resultados por grupo"
        selectedGroups={[
          'A_Contextos',
          'B_Direitos_do_Cliente',
          'C_Participacao',
          'D_Apoio_da_Equipa_Tecnica',
          'E_Avaliacao_Geral',
        ]}
        selectedGroupsLabels={[
          'A - Contextos',
          'B - Direitos do Cliente',
          'C - Participação',
          'D - Apoio da Equipa Técnica',
          'E - Avaliação Geral',
        ]}
        answerList={A2000_070_ANSWERS}
        surveyStats={surveyStats}
        style={{
          width: chartWidth,
          margin: 'auto',
          backgroundColor: 'white',
        }}
      />

      <ChartByQuestion
        title="A - Contextos"
        selectedQuestions={[1, 2, 3, 4, 5, 6]}
        answerList={A2000_070_ANSWERS}
        questionList={A2000_070_QUESTIONS}
        surveyStatsQuestions={surveyStats.questions}
        style={{
          width: chartWidth,
          margin: 'auto',
          marginTop: '15px',
          backgroundColor: 'white',
        }}
      />
      <ChartByQuestion
        title="B - Direitos do Cliente"
        selectedQuestions={[7, 8, 9, 10, 11]}
        answerList={A2000_070_ANSWERS}
        questionList={A2000_070_QUESTIONS}
        surveyStatsQuestions={surveyStats.questions}
        style={{
          width: chartWidth,
          margin: 'auto',
          marginTop: '15px',
          backgroundColor: 'white',
        }}
      />
      <ChartByQuestion
        title="C - Participação"
        selectedQuestions={[12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]}
        answerList={A2000_070_ANSWERS}
        questionList={A2000_070_QUESTIONS}
        surveyStatsQuestions={surveyStats.questions}
        height={250}
        style={{
          width: chartWidth,
          margin: 'auto',
          marginTop: '15px',
          backgroundColor: 'white',
        }}
      />
      <ChartByQuestion
        title="D - Apoio da Equipa Técnica"
        selectedQuestions={[24, 25, 26, 27, 28, 29, 30]}
        answerList={A2000_070_ANSWERS}
        questionList={A2000_070_QUESTIONS}
        surveyStatsQuestions={surveyStats.questions}
        height={175}
        style={{
          width: chartWidth,
          margin: 'auto',
          marginTop: '15px',
          backgroundColor: 'white',
        }}
      />
      <ChartByQuestion
        title="E - Avaliação Geral"
        selectedQuestions={[31, 32]}
        answerList={A2000_070_ANSWERS}
        questionList={A2000_070_QUESTIONS}
        surveyStatsQuestions={surveyStats.questions}
        height={80}
        style={{
          width: chartWidth,
          margin: 'auto',
          marginTop: '15px',
          backgroundColor: 'white',
        }}
      />
    </div>
  );
};

export default Stats_A2000_070;
