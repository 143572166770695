export const A2000_143_QUESTIONS = {
  1: 'Fui pontual e cumpri os horários das sessões.',
  2: 'Apresentei os objetivos das unidades e as competências a adquirir.',
  3: 'Utilizei os métodos e as estratégias pedagógicas adequadas à diversidade do grupo.',
  4: 'Utilizei os recursos mais adequados a cada sessão para a transmissão dos conteúdos (computador, fichas de trabalho, textos informativos, quadro, filmes, etc.).',
  5: 'Os textos de apoio distribuídos foram adequados, em quantidade e qualidade.',
  6: 'As propostas de trabalho (incluindo as saídas ao exterior) foram úteis para a aquisição dos conhecimentos /transmissão dos conteúdos.',
  7: 'Dominei os conteúdos abordados.',
  8: 'Transmiti com clareza os assuntos abordados.',
  9: 'Estive disponível para esclarecer as dúvidas apresentadas pelos formandos.',
  10: 'Promovi o espírito de iniciativa e a autonomia dos formandos.',
  11: 'Estimulei/despertei o interesse dos formandos pelos conteúdos ministrados.',
  12: 'Estimulei a participação dos formandos nas sessões.',
  13: 'Mantive a disciplina e respeito na sala de formação.',
  14: 'Estabeleci boa relação com todos os formandos, criando um clima de confiança.',
  15: 'Fui disponível para colaborar em diversas atividades (reuniões pedagógicas de conceção, de organização, reuniões de acompanhamento e avaliação, sugestões de melhoria…) dando sugestões/propostas de melhoria.',
  16: 'Cumpri os prazos estabelecidos (planificações, avaliações…) e realizei o preenchimento correto e dos documentos do Dossier Técnico-Pedagógico.',
};

export const A2000_143_ANSWERS = [
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '0', text: 'Não se aplica / Não sei' },
];
