import React from 'react';
import HeaderDate from '../../../components/HeaderDate';
import HeaderText from '../../../components/HeaderText';
import MultiCheckbox from '../../../components/multi-checkbox';
import QuestionHeader from '../../../components/question-header';

import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';
import SurveyTextarea from '../../../components/survey-textarea';
import SubmitSurvey from '../../../components/SubmitSurvey';

import {
  A2000_082_QUESTIONS,
  A2000_082_ANSWERS,
  A2000_082_ANSWERS_2,
  A2000_082_ANSWERS_Q7,
  A2000_082_ANSWERS_Q37,
  A2000_082_ANSWERS_Q39,
} from './Questions_A2000_082';

import '../Survey.css';

const Survey_A2000_082 = (props) => {
  const { onChange, text } = props;

  const groupA = [],
    groupB = [],
    groupC = [],
    groupD = [],
    groupE = [],
    groupF = [],
    groupH = [];

  //group A
  for (let i = 1; i <= 4; i++) {
    groupA.push(
      <SurveyQuestion
        answers={A2000_082_ANSWERS}
        questions={A2000_082_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //group B
  for (let i = 5; i <= 10; i++) {
    if (i === 7) {
      groupB.push(
        <div key={i}>
          <div key={i} className="container ml-2">
            <QuestionHeader questionNum={i}>
              {A2000_082_QUESTIONS[i]}
            </QuestionHeader>
            <div className="ml-4 form-check-inline">
              <label className="form-check-label" htmlFor={i + '_CP'}>
                Concordo Plenamente:
              </label>
              <SurveyQuestion
                onChange={onChange}
                name={'Q' + i + '_CP'}
                isSelect={true}
                width="300px"
                answers={A2000_082_ANSWERS_Q7}
              />
            </div>
            <div className="ml-4 form-check form-check-inline">
              <label className="form-check-label" htmlFor={i + '_C'}>
                Concordo:
              </label>
              <SurveyQuestion
                onChange={onChange}
                name={'Q' + i + '_C'}
                isSelect={true}
                width="300px"
                answers={A2000_082_ANSWERS_Q7}
              />
            </div>
          </div>
        </div>
      );
    } else {
      groupB.push(
        <SurveyQuestion
          answers={A2000_082_ANSWERS}
          questions={A2000_082_QUESTIONS}
          onChange={onChange}
          num={i}
          key={i}
        />
      );
    }
  }

  //group C
  for (let i = 11; i <= 15; i++) {
    groupC.push(
      <SurveyQuestion
        answers={A2000_082_ANSWERS}
        questions={A2000_082_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //group D
  for (let i = 16; i <= 24; i++) {
    groupD.push(
      <SurveyQuestion
        answers={A2000_082_ANSWERS}
        questions={A2000_082_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //group E
  for (let i = 25; i <= 32; i++) {
    groupE.push(
      <SurveyQuestion
        answers={A2000_082_ANSWERS}
        questions={A2000_082_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //group F
  for (let i = 33; i <= 36; i++) {
    groupF.push(
      <SurveyQuestion
        answers={A2000_082_ANSWERS}
        questions={A2000_082_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  //group H
  for (let i = 40; i <= 44; i++) {
    groupH.push(
      <SurveyQuestion
        answers={A2000_082_ANSWERS_2}
        questions={A2000_082_QUESTIONS}
        onChange={onChange}
        num={i}
        key={i}
      />
    );
  }

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <HeaderText name="name" placeholder="Nome" />

          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup name="CAPACITAÇÃO">
        {groupA}
        <SurveyTextarea
          id="just_A"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="MOTIVAÇÃO">
        {groupB}
        <SurveyTextarea
          id="just_B"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="CONDIÇÕES DE TRABALHO">
        {groupC}
        <SurveyTextarea
          id="just_C"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="DESCENTRALIZAÇÃO">
        {groupD}
        <SurveyTextarea
          id="just_D"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="AMBIENTE RELACIONAL">
        {groupE}
        <SurveyTextarea
          id="just_E"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="REALIZAÇÃO PESSOAL">
        {groupF}
        <MultiCheckbox
          answers={A2000_082_ANSWERS_Q37}
          questions={A2000_082_QUESTIONS}
          onChange={onChange}
          num={'37'}
          key={'37'}
        />
        <SurveyTextarea
          id="groupE_just"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="MUDANÇA">
        <SurveyQuestion
          answers={A2000_082_ANSWERS}
          questions={A2000_082_QUESTIONS}
          onChange={onChange}
          num={'38'}
          key={'38'}
        />
        <SurveyQuestion
          answers={A2000_082_ANSWERS_Q39}
          questions={A2000_082_QUESTIONS}
          onChange={onChange}
          num={'39'}
          key={'39'}
        />
        <SurveyTextarea
          id="just_G"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup name="TRABALHO E ESPAÇO DE VIDA">
        {groupH}
        <SurveyTextarea
          id="just_H"
          rows="6"
          onChange={onChange}
          placeholder="Justifique as respostas negativas"
        />
      </SurveyGroup>
      <SurveyGroup>
        <SurveyTextarea
          id="expectativas"
          onChange={onChange}
          placeholder="Quais as suas expectativas relativamente à A2000?"
        />
        <SurveyTextarea
          id="melhor"
          onChange={onChange}
          placeholder="O que considera melhor na A2000?"
        />
        <SurveyTextarea
          id="mudava"
          onChange={onChange}
          placeholder="O que mudaria na A2000?"
        />
        <SubmitSurvey />
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_082;
