import React, { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Grid, makeStyles, Container } from '@material-ui/core';
import { useHttpClient } from '../shared/hooks/http-hook';
import { message, Popconfirm } from 'antd';
import { AuthContext } from '../shared/context/auth-context';
import EditLocationModal from './components/EditLocationModal';

const Locations = () => {
  const [loadedServices, setLoadedServices] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [serviceListToggle, setServiceListToggle] = useState(false);
  const [service, setService] = useState({ name: '', description: '' });
  const auth = useContext(AuthContext);

  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/locations`
        );

        setLoadedServices(
          responseData.data.map((serv) => {
            serv.key = serv._id;
            return serv;
          })
        );
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, serviceListToggle]);

  const columns = [
    { field: 'name', headerName: 'Nome', width: 450 },

    {
      field: '',
      headerName: '',
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              onClick={() => handleUpdate(params.row)}
              className="btn btn-outline-primary"
            >
              Editar
            </button>
            <Popconfirm
              title={
                <div>
                  <p>Deseja eliminar este local?</p>
                  <p>
                    <strong>
                      Irá também remover todas as avaliações deste local.
                    </strong>
                  </p>
                </div>
              }
              onConfirm={() => handleDelete(params.row._id)}
              okText="Sim"
              cancelText="Não"
            >
              <button className="btn btn-outline-danger">Eliminar</button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const rows = loadedServices.map((serv) => {
    serv.id = serv._id;
    return serv;
  });

  const handleUpdate = (record) => {
    setService({
      name: record.name,
      description: record.description,
    });
    setUpdateId(record._id);
    console.log(record._id);
    setIsUpdate(true);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setService({
      name: '',
      description: '',
    });
    setUpdateId(null);
    setIsUpdate(false);
    setModalVisible(false);
  };

  const handleSave = async () => {
    const url = isUpdate
      ? process.env.REACT_APP_BACKEND_URL + '/locations/' + updateId
      : process.env.REACT_APP_BACKEND_URL + '/locations';

    try {
      const responseData = await sendRequest(
        url,
        isUpdate ? 'PUT' : 'POST',
        JSON.stringify(service),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.success) {
        message.success(
          isUpdate ? 'Local atualizado com sucesso' : 'Local criado com sucesso'
        );
        setServiceListToggle(!serviceListToggle);
        handleCancel();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (id) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/locations/' + id,
        'DELETE',
        undefined,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.success) {
        message.success('Local removido com sucesso');
        setServiceListToggle(!serviceListToggle);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setService({
      ...service,
      [e.target.name]: e.target.value,
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    container: {
      marginTop: '20px',
      height: '40px',
      backgroundColor: 'black',
    },
    grid: {
      //backgroundColor: 'purple',
      padding: '2px',
    },
    button: {
      margin: '10px',
    },
  }));

  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <EditLocationModal
        visible={modalVisible}
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleChange={handleChange}
        service={service}
        update={isUpdate}
      />
      <Grid xs={12} className={classes.grid}>
        <button
          className="btn btn-success m-2"
          onClick={() => setModalVisible(true)}
        >
          Novo Local
        </button>
      </Grid>
      <div
        style={{
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          className={classes.root}
        />
      </div>
    </Container>
  );
};

export default Locations;
