import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../shared/context/auth-context';
import {
  A2000_082_ANSWERS,
  A2000_082_ANSWERS_2,
  A2000_082_QUESTIONS,
  A2000_082_ANSWERS_Q7,
  A2000_082_ANSWERS_Q37,
  A2000_082_ANSWERS_Q39,
} from './Questions_A2000_082';
//import 'moment/locale/pt-pt';
import locale from 'antd/es/date-picker/locale/pt_PT';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt';
import ChartByQuestion from '../charts/ChartByQuestion';
import ChartByGroup from '../charts/ChartByGroup';
import QuestionTable from '../../../components/data/QuestionTable';
import ChartBySingleQuestion from '../charts/ChartBySingleQuestion';

const Stats_A2000_082 = ({ text }) => {
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [surveyStats, setSurveyStats] = useState({});
  const [selectedDates, setSelectedDates] = useState([
    moment('01-01-' + (new Date().getFullYear() - 1), 'DD-MM-YYYY'),
    moment('31-12-' + new Date().getFullYear(), 'DD-MM-YYYY'),
  ]);

  const chartWidth = '100%';

  const auth = useContext(AuthContext);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/surveys/a2000_082/stats`,
          'POST',
          JSON.stringify({
            startdate: selectedDates[0].format(),
            enddate: selectedDates[1].format(),
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setSurveyStats(responseData.data);
        console.log(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [sendRequest, auth.token, selectedDates]);

  const handleDatesChange = (date) => {
    if (date) {
      setSelectedDates(date);
    }
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <div className="center mt-2 mb-4 no-print">
          <RangePicker
            allowClear={false}
            defaultValue={selectedDates}
            locale={locale}
            onChange={handleDatesChange}
            style={{ marginRight: '10px' }}
          />
        </div>
        {surveyStats.totals1 && surveyStats.totals1.totalSurveys > 0 && (
          <div className='print'>
            <div className="center">
              <p className="text-white h4">
                Número de avaliações: {surveyStats.totals1.totalSurveys}
              </p>
            </div>

            <div className="center bg-dark mt-4">
              <p className="text-white h4">Questões 1-6, 8-36, 38</p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Concordo Plenamente:{' '}
                {(
                  (surveyStats.totals1['Concordo Plenamente'] /
                    surveyStats.totals1.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                %{' '}
                {' (' + (surveyStats.totals1['Concordo Plenamente'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Concordo:{' '}
                {(
                  (surveyStats.totals1['Concordo'] /
                    surveyStats.totals1.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals1['Concordo'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Concordo Pouco:{' '}
                {(
                  (surveyStats.totals1['Concordo Pouco'] /
                    surveyStats.totals1.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals1['Concordo Pouco'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Não Concordo:{' '}
                {(
                  (surveyStats.totals1['Não Concordo'] /
                    surveyStats.totals1.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals1['Não Concordo'] || 0) + ')'}
              </p>
            </div>
          </div>
        )}
        {surveyStats.miscQuestions && surveyStats.totals1.totalSurveys > 0 && (
          <div className='print'>
            <div className="center bg-dark mt-4">
              <p className="text-white h4">Questão 7 (Concordo Plenamente)</p>
            </div>

            {A2000_082_ANSWERS_Q7.map((ans, i) => {
              return (
                <div className="center" key={i}>
                  <p className="text-white h6">
                    {ans.text}:{' '}
                    {(
                      (surveyStats.miscQuestions['Q7_CP'][ans.text] /
                        surveyStats.miscQuestions['Q7_CP'].totals
                          .validAnswers) *
                        100 || 0
                    ).toFixed(2)}
                    %{' '}
                    {' (' +
                      (surveyStats.miscQuestions['Q7_CP'][ans.text] || 0) +
                      ')'}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {surveyStats.miscQuestions && surveyStats.totals1.totalSurveys > 0 && (
          <div className='print'>
            <div className="center bg-dark mt-4">
              <p className="text-white h4">Questão 7 (Concordo)</p>
            </div>

            {A2000_082_ANSWERS_Q7.map((ans, i) => {
              return (
                <div className="center" key={i}>
                  <p className="text-white h6">
                    {ans.text}:{' '}
                    {(
                      (surveyStats.miscQuestions['Q7_C'][ans.text] /
                        surveyStats.miscQuestions['Q7_C'].totals.validAnswers) *
                        100 || 0
                    ).toFixed(2)}
                    %{' '}
                    {' (' +
                      (surveyStats.miscQuestions['Q7_C'][ans.text] || 0) +
                      ')'}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {surveyStats.miscQuestions && surveyStats.totals1.totalSurveys > 0 && (
          <div className='print'>
            <div className="center bg-dark mt-4">
              <p className="text-white h4">Questão 37</p>
            </div>

            {A2000_082_ANSWERS_Q37.map((ans, i) => {
              return (
                <div className="center" key={i}>
                  <p className="text-white h6">
                    {ans.text}:{' '}
                    {(
                      (surveyStats.miscQuestions['Q37'][ans.text] /
                        surveyStats.miscQuestions['Q37'].totals.validAnswers) *
                        100 || 0
                    ).toFixed(2)}
                    %{' '}
                    {' (' +
                      (surveyStats.miscQuestions['Q37'][ans.text] || 0) +
                      ')'}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {surveyStats.miscQuestions && surveyStats.totals1.totalSurveys > 0 && (
          <div className='print'>
            <div className="center bg-dark mt-4">
              <p className="text-white h4">Questão 39</p>
            </div>

            {A2000_082_ANSWERS_Q39.map((ans, i) => {
              if (ans.value <= 0) return <div key={i}></div>;
              return (
                <div className="center" key={i}>
                  <p className="text-white h6" key={i}>
                    {ans.text}:{' '}
                    {(
                      (surveyStats.miscQuestions['Q39'][ans.text] /
                        surveyStats.miscQuestions['Q39'].totals.validAnswers) *
                        100 || 0
                    ).toFixed(2)}
                    %{' '}
                    {' (' +
                      (surveyStats.miscQuestions['Q39'][ans.text] || 0) +
                      ')'}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {surveyStats.totals2 && (
          <div className='print'>
            <div className="center bg-dark mt-4">
              <p className="text-white h4">Questões 40-44</p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Sempre:{' '}
                {(
                  (surveyStats.totals2['Sempre'] /
                    surveyStats.totals2.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals2['Sempre'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Moderadamente:{' '}
                {(
                  (surveyStats.totals2['Moderadamente'] /
                    surveyStats.totals2.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals2['Moderadamente'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Raramente:{' '}
                {(
                  (surveyStats.totals2['Raramente'] /
                    surveyStats.totals2.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals2['Raramente'] || 0) + ')'}
              </p>
            </div>
            <div className="center">
              <p className="text-white h6">
                Nunca:{' '}
                {(
                  (surveyStats.totals2['Nunca'] /
                    surveyStats.totals2.validAnswers) *
                    100 || 0
                ).toFixed(2)}
                % {' (' + (surveyStats.totals2['Nunca'] || 0) + ')'}
              </p>
            </div>
          </div>
        )}
        <br/>
        <br/>
        <br/>
        <br/>
        <QuestionTable
          questionCols={['1', '2', '3', '4', '5']}
          questionStart={1}
          questionEnd={38}
          excludeQuestions={[7, 37]}
          questionsList={surveyStats.questions1}
          totals={surveyStats.totals1}
          questionsText={A2000_082_QUESTIONS}
          answerList={A2000_082_ANSWERS}
          invalidAnswer="Não se Aplica"
        ></QuestionTable>
        <QuestionTable
          questionCols={['1', '2', '3', '4', '5']}
          questionStart={40}
          questionEnd={44}
          excludeQuestions={[]}
          questionsList={surveyStats.questions2}
          totals={surveyStats.totals2}
          questionsText={A2000_082_QUESTIONS}
          answerList={A2000_082_ANSWERS_2}
          invalidAnswer="Não se Aplica"
        ></QuestionTable>
        <ChartByGroup
          title="Distribuição das Respostas por Área"
          selectedGroups={[
            'Capacitacao',
            'Motivacao',
            'CondicoesTrabalho',
            'Descentralizacao',
            'AmbienteRelacional',
            'RealizacaoPessoal',
          ]}
          selectedGroupsLabels={[
            'Capacitação',
            'Motivação',
            'Condições de Trabalho',
            'Descentralização',
            'Ambiente Relacional',
            'Realização Pessoal',
          ]}
          answerList={A2000_082_ANSWERS}
          surveyStats={surveyStats}
          style={{
            width: chartWidth,
            margin: 'auto',
            backgroundColor: 'white',
          }}
        />
        <ChartByQuestion
          title="Trabalho e Espaço de Vida: Efeitos Negativos do trabalho na Vida Pessoal"
          selectedQuestions={[40, 41, 43]}
          answerList={A2000_082_ANSWERS_2}
          questionList={A2000_082_QUESTIONS}
          surveyStatsQuestions={surveyStats.questions2}
          reverseColors
          height="120px"
          style={{
            width: chartWidth,
            margin: 'auto',
            marginTop: '30px',
            backgroundColor: 'white',
          }}
        />
        <ChartByQuestion
          title="Trabalho e Espaço de Vida: Efeitos Positivos"
          selectedQuestions={[42, 44]}
          answerList={A2000_082_ANSWERS_2}
          questionList={A2000_082_QUESTIONS}
          surveyStatsQuestions={surveyStats.questions2}
          //reverseColors
          height="120px"
          style={{
            width: chartWidth,
            margin: 'auto',
            marginTop: '30px',
            backgroundColor: 'white',
          }}
        />
        <ChartByQuestion
          title="Avaliação da Satisfação 1/2"
          selectedQuestions={[
            1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          ]}
          answerList={A2000_082_ANSWERS}
          questionList={A2000_082_QUESTIONS}
          surveyStatsQuestions={surveyStats.questions1}
          height="400px"
          //reverseColors
          style={{
            width: chartWidth,
            margin: 'auto',
            marginTop: '30px',
            backgroundColor: 'white',
          }}
        />
        <ChartByQuestion
          title="Avaliação da Satisfação 2/2"
          selectedQuestions={[
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38,
          ]}
          answerList={A2000_082_ANSWERS}
          questionList={A2000_082_QUESTIONS}
          surveyStatsQuestions={surveyStats.questions1}
          height="400px"
          //reverseColors
          style={{
            width: chartWidth,
            margin: 'auto',
            marginTop: '15px',
            backgroundColor: 'white',
          }}
        />
        <ChartBySingleQuestion
          title="Questão 37"
          selectedQuestions={[37]}
          answerList={A2000_082_ANSWERS_Q37}
          questionList={A2000_082_QUESTIONS}
          surveyStatsQuestions={surveyStats.miscQuestions}
          ignoreAnswerThreshold="-1"
          singleQuestionName="Q37"
          largePalette
          style={{
            width: chartWidth,
            margin: 'auto',
            marginTop: '15px',
            backgroundColor: 'white',
          }}
        />
      </div>
    </div>
  );
};

export default Stats_A2000_082;
