export const A2000_070_QUESTIONS = {
  1: 'Espaços adequados (salas, conforto, acessibilidades, etc.).',
  2: 'Materiais e equipamentos para desenvolver as atividades.',
  3: 'Limpeza e higiene das instalações.',
  4: 'Transporte assegurado pela A2000 (segurança, conforto, horário, etc.).',
  5: 'Variedade e qualidade das ementas.',
  6: 'Qualidade de confeção dos alimentos.',
  7: 'Teve informação sobre o funcionamento do serviço (cronograma, objetivos, horários, Regulamento Interno / Manual de Acolhimento, etc.).',
  8: 'Teve informação sobre os direitos e deveres (carta dos direitos).',
  9: 'Os direitos individuais foram respeitados.',
  10: 'Participou no planeamento do serviço.',
  11: 'Teve informação sobre os serviços da A2000.',
  12: 'O cliente desenvolveu as suas competências (profissionais / ocupacionais).',
  13: 'O cliente aumentou a sua autonomia.',
  14: 'O cliente foi capaz de tomar mais decisões sozinho.',
  15: 'O cliente fez sugestões e foram respeitadas.',
  16: 'O cliente/significativo reclamou sobre alguma coisa e deram-lhe resposta.',
  17: 'O cliente/significativo participou na elaboração do seu Plano Individual.',
  18: 'O cliente/significativo participou na avaliação do seu Plano Individual.',
  19: 'Os parceiros contribuíram para a concretização dos objetivos do Plano Individual do cliente.',
  20: 'As atividades foram adequadas às necessidades e expetativas do cliente.',
  21: 'O cliente ganhou mais amigos.',
  22: 'O cliente realizou, com o serviço, diferentes atividades na comunidade.',
  23: 'O cliente está mais incluído na comunidade.',
  24: 'Os colaboradores foram simpáticos e educados.',
  25: 'O cliente foi compreendido/a e respeitado pelos colaboradores nos seus direitos, valores, crenças.',
  26: 'Os colaboradores apoiaram o cliente para alcançar os seus objetivos e a resolver os seus problemas.',
  27: 'Os colaboradores respeitaram a privacidade e a confidencialidade da informação do cliente.',
  28: 'Os colaboradores incentivaram o cliente a fazer mais e melhor.',
  29: 'Os colaboradores ouviram o cliente e deram-lhe explicações claras sobre os assuntos que ele/a expôs.',
  30: 'Os voluntários mostraram-se disponíveis e atenciosos.',
  31: 'Considero que o serviço prestado tem qualidade?',
  32: 'A A2000 contribuiu para a melhoria da qualidade de vida do cliente.',
};

export const A2000_070_ANSWERS = [
  { value: '4', text: 'Muito Satisfeito' },
  { value: '3', text: 'Satisfeito' },
  { value: '2', text: 'Pouco Satisfeito' },
  { value: '1', text: 'Nada Satisfeito' },
  { value: '0', text: 'Não se aplica / Não sei' },
];
