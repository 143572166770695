import React, { useContext, useState, useEffect } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const SelectLocation = ({ placeholder = 'Local', todos = false }) => {
  const surveyCtx = useContext(SurveyContext);
  const auth = useContext(AuthContext);

  const [loadedData, setLoadedData] = useState([]);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/locations?select=name&sort=name`,
          'GET',
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        setLoadedData(responseData.data);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth.token]);

  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value || undefined);
  };
  return (
    <div className="col-xs-2 form-inline m-2">
      <select
        onChange={onChangeHandler}
        className="form-control"
        id="location"
        name="location"
        value={
          surveyCtx.values['location'] ? surveyCtx.values['location']._id : ''
        }
        style={{ width: '200px', maxWidth: '220px' }}
      >
        {!todos ? (
          <option value="" disabled>
            {placeholder}
          </option>
        ) : (
          <option value="*">Todos</option>
        )}

        {loadedData.map((data) => (
          <option key={data._id} value={data._id}>
            {data.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectLocation;
