import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

const HeaderDate = () => {
  const surveyCtx = useContext(SurveyContext);

  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value || undefined);
  };

  return (
    <div className="col-xs-2 form-inline m-2">
      <input
        placeholder="Data"
        onChange={onChangeHandler}
        value={surveyCtx.values['date'] || ''}
        className="ml-1 form-control"
        type="date"
        name="date"
        id="date"
        style={{ width: 'auto', maxWidth: '175px' }}
      />
    </div>
  );
};

export default HeaderDate;
