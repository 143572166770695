import React from 'react';
import SurveyGroup from '../../../components/survey-group';
import SurveyQuestion from '../../../components/survey-question';

import '../Survey.css';
import {
  A2000_165_QUESTIONS,
  A2000_165_ANSWERS,
  A2000_165_ANSWERS_2,
} from './Questions_A2000_165';
import SurveyTextarea from '../../../components/survey-textarea';
import HeaderDate from '../../../components/HeaderDate';
import HeaderService from '../../../components/HeaderService';
import SubmitSurvey from '../../../components/SubmitSurvey';
import SelectFormador from '../../../components/SelectFormador';
import SelectLocation from '../../../components/SelectLocation';

const Survey_A2000_165 = ({ onChange, text }) => {
  const generateQuestions = (start, end, answers) => {
    const retArray = [];

    for (let i = start; i <= end; i++) {
      retArray.push(
        <SurveyQuestion
          answers={answers}
          questions={A2000_165_QUESTIONS}
          onChange={onChange}
          num={i}
          key={i}
        />
      );
    }
    return retArray;
  };

  return (
    <div className="mb-5 mt-4 d-block align-content-center w-100">
      <div className="container">
        <h2 className="center survey-text mb-5">{text}</h2>
        <div className="form-group row survey-card survey-bg center m-0 p-2">
          <HeaderDate />
          <SelectFormador />
          <SelectLocation />
          <HeaderService placeholder="Ação" />
          <SubmitSurvey inline />
        </div>
      </div>
      <SurveyGroup name="I - Avaliação de Satisfação das Condições Físicas">
        {generateQuestions(1, 4, A2000_165_ANSWERS)}
      </SurveyGroup>
      <SurveyGroup name="II - Avaliação de Satisfação dos Aspectos Pedagógicos">
        {generateQuestions(5, 10, A2000_165_ANSWERS)}
      </SurveyGroup>
      <SurveyGroup name="III - Avaliação de Satisfação do Apoio da A2000">
        {generateQuestions(11, 15, A2000_165_ANSWERS)}
      </SurveyGroup>
      <SurveyGroup name="IV - Avaliação Global">
        {generateQuestions(16, 16, A2000_165_ANSWERS)}
        {generateQuestions(17, 18, A2000_165_ANSWERS_2)}
      </SurveyGroup>
      <SurveyGroup name="V - Sugestões de Melhoria">
        <SurveyTextarea
          id="sugestoes"
          onChange={onChange}
          placeholder="Sugestões"
        />
        <SubmitSurvey />
      </SurveyGroup>
    </div>
  );
};

export default Survey_A2000_165;
