import React, { useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';

const HeaderSelect = ({
  options = [],
  name = 'select',
  placeholder = 'Escolha',
  style = { width: 'auto', maxWidth: '200px' },
}) => {
  const surveyCtx = useContext(SurveyContext);

  const onChangeHandler = (e) => {
    surveyCtx.setValue(e.target.name, e.target.value || undefined);
  };

  return (
    <div className="col-xs-2 form-inline m-2">
      <select
        onChange={onChangeHandler}
        className="form-control"
        id={name}
        name={name}
        value={surveyCtx.values[name] || ''}
        style={style}
      >
        <option value="" disabled>
          {placeholder}
        </option>

        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HeaderSelect;
