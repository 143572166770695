import React from 'react';
import { HorizontalBar } from '@reactchartjs/react-chart.js';
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';

const ChartBySingleQuestion = ({
  title,
  selectedQuestions,
  answerList,
  questionList,
  surveyStatsQuestions,
  customTotal = null,
  height = 150,
  style = {},
  ignoreAnswerThreshold = 0,
  singleQuestionName = null,
  barColor = 'rgb(112, 177, 255)',
}) => {
  if (!surveyStatsQuestions) return <div></div>;

  title = selectedQuestions[0] + ' - ' + questionList[selectedQuestions[0]];
  var yourSplit = function (N, string) {
    var app = string.split(' '),
      arrayApp = [],
      stringApp = '';
    app.forEach(function (sentence, index) {
      stringApp += sentence + ' ';

      if ((index + 1) % N === 0) {
        arrayApp.push(stringApp);
        stringApp = '';
      } else if (app.length === index + 1 && stringApp !== '') {
        arrayApp.push(stringApp);
        stringApp = '';
      }
    });
    return arrayApp;
  };

  const data = {
    labels: answerList
      .filter((q) => q.value > ignoreAnswerThreshold)
      .map((q) => yourSplit(5, q.text)),
    datasets: [
      {
        label: '',
        data: answerList
          .filter((q) => q.value > ignoreAnswerThreshold)
          .map((q) =>
            surveyStatsQuestions[singleQuestionName]
              ? (
                  (surveyStatsQuestions[singleQuestionName][q.text] /
                    (customTotal
                      ? customTotal
                      : surveyStatsQuestions[singleQuestionName].totals
                          .validAnswers)) *
                  100
                ).toFixed(2)
              : 0
          ),
        backgroundColor: barColor,
      },
    ],
  };

  const options = {
    title: {
      display: title,
      text:
        title ||
        selectedQuestions[0] + '. ' + questionList[selectedQuestions[0]],
      fontSize: 20,
    },
    legend: {
      display: false,
      labels: {
        fontSize: 14,
        fontColor: 'black',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 1,
            max: 100,
            fontSize: 13,
            fontColor: 'black',
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          if (value === '0.00') return '';
          else return value + '%';
        },
        color: '#000000',
        font: {
          size: 11,
        },
        anchor: 'end',
        align: function (value, context) {
          if (value.dataset.data[value.dataIndex] > 90) return 'start';
          else return 'end';
        },
      },
    },
  };

  return (
    <div style={style}>
      <HorizontalBar height={height} data={data} options={options} />
    </div>
  );
};

export default ChartBySingleQuestion;
