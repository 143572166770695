import React, { useState, useEffect, useContext } from 'react';
import { SurveyContext } from '../../shared/context/survey-context';
import { Select } from 'antd';
import { useHttpClient } from '../../shared/hooks/http-hook';

const HeaderServiceMultiple = ({placeholder = 'Serviço' }) => {
  const [loadedServices, setLoadedServices] = useState([]);
  const surveyCtx = useContext(SurveyContext);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { Option } = Select;

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/services?select=name&sort=name`
        );

        setLoadedServices(responseData.data);
      } catch (err) {}
    };
    fetchServices();
  }, [sendRequest]);

  const onChangeHandler = (e) => {
    surveyCtx.setValue("serviceMultiple", e || undefined);

    console.log(e)

    //console.log(surveyCtx)
  };

  let selectedServices;

  if(surveyCtx.values) {
    if(Array.isArray(surveyCtx.values.serviceMultiple)){
      selectedServices = surveyCtx.values.serviceMultiple.map((s) => s._id)
      console.log(selectedServices)
    }
  }

  

  return (
    <div className="w-50">
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={onChangeHandler}
        defaultValue={selectedServices}
      >
        {loadedServices.map((serv, key) => {
          return (
            <Option key={key} value={serv._id}>
              {serv.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default HeaderServiceMultiple;
